import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import FeedbackAPI from "../../api/feedback";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { Feedback } from "../../graphql/API";
import styles from "./index.module.scss";

export default function FeedbackList() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>();

  const feedbackAPI = new FeedbackAPI();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    initialize(currentShopId);
  }, [currentShopId]);

  async function initialize(shopId: string) {
    const data = await feedbackAPI.list(shopId);

    setFeedbacks(data);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>피드백 기록</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>디자이너</th>
                  <th>만족도</th>
                  <th>피드백</th>
                  <th>응원 메시지</th>
                  <th>작성일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {feedbacks?.map((feedback) => {
                  return (
                    <tr key={feedback.bookingId}>
                      <td>{feedback.date}</td>
                      <td>
                        {feedback.designerName}
                        <br />
                        <small style={{ color: "gray" }}>
                          {feedback.shopName}
                        </small>
                      </td>
                      <td>{feedback.satisfaction}</td>
                      <td>{feedback.comment}</td>
                      <td>{feedback.supportMessage}</td>
                      <td>
                        {dayjs(feedback.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
