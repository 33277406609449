import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface CommonState {
  currentShopId?: string;
  userGroups: string[];
  showUserModal: boolean;
  showShopModal: boolean;
  showSpinner: boolean;
}

const initialState: CommonState = {
  currentShopId: undefined,
  userGroups: [],
  showUserModal: false,
  showShopModal: false,
  showSpinner: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setCurrentShopId: (state, action: PayloadAction<string>) => {
      state.currentShopId = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<string[]>) => {
      state.userGroups = action.payload;
    },
    setShowUserModal: (state, action: PayloadAction<boolean>) => {
      state.showUserModal = action.payload;
    },
    setShowShopModal: (state, action: PayloadAction<boolean>) => {
      state.showShopModal = action.payload;
    },
    setShowSpinner: (state, action: PayloadAction<boolean>) => {
      state.showSpinner = action.payload;
    },
  },
});

export const selectCurrentShopId = (state: RootState) =>
  state.common.currentShopId;

export const selectUserGroups = (state: RootState) => state.common.userGroups;

export const selectShowUserModal = (state: RootState) =>
  state.common.showUserModal;

export const selectShowShopModal = (state: RootState) =>
  state.common.showShopModal;

export const selectShowSpinner = (state: RootState) => state.common.showSpinner;

export const {
  setCurrentShopId,
  setUserGroups,
  setShowUserModal,
  setShowShopModal,
  setShowSpinner,
} = commonSlice.actions;

export default commonSlice.reducer;
