import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../App";
import { Container, Row, Col, Table } from "react-bootstrap";
import dayjs from "dayjs";

export default function ConsultationRequestDetails() {
  const collectionName = "consultation_requests";

  let { id } = useParams();

  const [requestData, setRequestData] = useState<any>({});

  useEffect(() => {
    load(id as string);
  }, [id]);

  const navigate = useNavigate();

  async function load(id: string) {
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);

    console.log(docSnap.data());

    setRequestData(docSnap.data());
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>비대면 상담 신청</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <a
              href="javascript:;"
              onClick={() => {
                navigate(-1);
              }}
            >
              목록으로 돌아가기
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table style={{ marginTop: 30 }}>
              <tbody>
                <tr>
                  <th>등록일시</th>
                  <td>
                    {requestData.createdAt
                      ? dayjs(requestData.createdAt.toDate()).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td>{requestData.email}</td>
                </tr>
                <tr>
                  <th>성별</th>
                  <td>{requestData.gender}</td>
                </tr>
                <tr>
                  <th>연령</th>
                  <td>{requestData.age}</td>
                </tr>
                <tr>
                  <th>직업</th>
                  <td>{requestData.job}</td>
                </tr>
                <tr>
                  <th>얼굴형</th>
                  <td>{requestData.faceShape}</td>
                </tr>
                <tr>
                  <th>이마선코너</th>
                  <td>{requestData.hairLineCorner}</td>
                </tr>
                <tr>
                  <th>길어보이는곳</th>
                  <td>{requestData.longFacePart}</td>
                </tr>
                <tr>
                  <th>볼륨희망</th>
                  <td>{requestData.volumeNeeds}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table>
              <tbody>
                <tr>
                  <th>정면</th>
                  <td>
                    <img src={requestData.imageUrl1} style={{ height: 200 }} />
                  </td>
                </tr>
                <tr>
                  <th>45도 왼쪽 측면</th>
                  <td>
                    <img src={requestData.imageUrl2} style={{ height: 200 }} />
                  </td>
                  <th>45도 오른쪽 측면</th>
                  <td>
                    <img src={requestData.imageUrl3} style={{ height: 200 }} />
                  </td>
                </tr>
                <tr>
                  <th>90도 왼쪽 측면</th>
                  <td>
                    <img src={requestData.imageUrl4} style={{ height: 200 }} />
                  </td>
                  <th>90도 오른쪽 측면</th>
                  <td>
                    <img src={requestData.imageUrl5} style={{ height: 200 }} />
                  </td>
                </tr>
                <tr>
                  <th>본인이 원하는 헤어스타일</th>
                  <td>
                    <img src={requestData.imageUrl6} style={{ height: 200 }} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
