import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import APIGateway from "../../api/api-gateway";
import { CreateEventInput } from "../../graphql/API";

export default function EventList() {
  const apiGateway = new APIGateway();
  const defaultInput = (): CreateEventInput => {
    return {
      name: "",
      hostName: "",
      city: "",
      yearMonth: "",
      startDate: "",
    };
  };

  const [input, setInput] = useState<CreateEventInput>(defaultInput());

  async function create() {
    try {
      let yearMonth = input.startDate.replace(/-/g, "").substring(0, 6);

      await apiGateway.event.create({
        ...input,
        yearMonth,
      });

      toast.success("생성되었습니다.");

      setInput(defaultInput());
    } catch (error: any) {
      toast.error(error.errors[0]?.message);
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <Form.Control
            placeholder="이벤트 이름"
            value={input?.name}
            onChange={(event) => {
              setInput({
                ...input,
                name: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="주최"
            value={input?.hostName ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                hostName: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="스피커(강사)"
            value={input?.speakerName ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                speakerName: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="도시"
            value={input?.city ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                city: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="장소"
            value={input?.venue ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                venue: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="링크"
            value={input?.link ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                link: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="시작일"
            value={input?.startDate ?? ""}
            onChange={(event) => {
              setInput({
                ...input,
                startDate: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            placeholder="설명"
            value={input?.description ?? ""}
            as="textarea"
            rows={10}
            onChange={(event) => {
              setInput({
                ...input,
                description: event.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <Button onClick={create}>등록</Button>
        </Col>
      </Row>
    </Container>
  );
}
