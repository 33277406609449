import { API, Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CognitoUser } from "..";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCurrentShopId,
  selectShowUserModal,
  setShowUserModal,
} from "../../../features/common";
import { selectShops } from "../../../features/shop";

export default function UserModal({ user }: { user?: CognitoUser }) {
  const show = useAppSelector(selectShowUserModal);

  const dispatch = useDispatch();

  const currentShopId = useAppSelector(selectCurrentShopId);

  const shops = useAppSelector(selectShops);

  const handleClose = () => {
    dispatch(setShowUserModal(false));
  };

  const [email, setEmail] = useState("");
  const [input, setInput] = useState<{
    designerName: string;
    shopId: string;
    shopName: string;
    businessId: string;
    bizItemId: string;
  }>({
    designerName: "",
    shopId: "",
    shopName: "",
    businessId: "",
    bizItemId: "",
  });

  useEffect(() => {
    const email =
      user?.Attributes.find((item) => item.Name === "email")?.Value ?? "";

    setEmail(email);

    setInput({
      designerName:
        user?.Attributes.find((item) => item.Name === "custom:designer_name")
          ?.Value ?? "",
      shopId:
        user?.Attributes.find((item) => item.Name === "custom:shop_id")
          ?.Value ?? "",
      shopName:
        user?.Attributes.find((item) => item.Name === "custom:shop_name")
          ?.Value ?? "",
      businessId:
        user?.Attributes.find((item) => item.Name === "custom:business_id")
          ?.Value ?? "",
      bizItemId:
        user?.Attributes.find((item) => item.Name === "custom:biz_item_id")
          ?.Value ?? "",
    });
  }, [user]);

  async function updateUserAttributes() {
    if (!user) {
      return;
    }

    const { designerName, shopId, shopName, businessId, bizItemId } = input;

    let apiName = "AdminQueries";
    let path = "/updateUserAttributes";
    let myInit = {
      body: {
        username: user.Username,
        designerName,
        shopId: shopId || currentShopId,
        shopName,
        businessId,
        bizItemId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    await API.post(apiName, path, myInit);

    try {
      const isProduction = process.env.REACT_APP_STAGE === "prod";

      const url =
        (isProduction
          ? "https://n3oq1x0ylf.execute-api.ap-northeast-2.amazonaws.com/prod"
          : "https://qxw2yc8z0f.execute-api.ap-northeast-2.amazonaws.com/staging") +
        "/sync-designer-record";

      await axios.post(url, {
        userId: user.Attributes.find((item) => item.Name === "sub")?.Value,
      });
    } catch (error) {}
  }

  function onShopIdChange(shopId: string) {
    const shop = shops.find((item) => item.id === shopId);

    setInput({
      ...input,
      shopId,
      shopName: shop?.name ?? "",
    });
  }

  async function handleSaveChanges() {
    await updateUserAttributes();
    toast.success("업데이트했습니다.");
    location.reload();
  }

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{email}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>{email}</Col>
            <Col>
              {user.Groups?.map((group) => (
                <Badge
                  key={group.GroupName}
                  bg={group.GroupName === "Admin" ? "primary" : "success"}
                  style={{ marginRight: 5 }}
                >
                  {group.GroupName}
                </Badge>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  성명
                </Form.Label>
                <Form.Control
                  value={input.designerName}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      designerName: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  매장 ID
                </Form.Label>
                <Form.Select
                  value={input.shopId}
                  onChange={(event) => {
                    onShopIdChange(event.target.value);
                  }}
                >
                  <option value="">-선택-</option>
                  {shops.map((shop) => {
                    return (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  네이버 업체 고유 ID
                </Form.Label>
                <Form.Control
                  value={input.businessId}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      businessId: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  네이버 상품 고유 ID
                </Form.Label>
                <Form.Control
                  value={input.bizItemId}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      bizItemId: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            저장
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
