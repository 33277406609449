import { API, graphqlOperation } from "aws-amplify";
import {
  SelfDiagnosisRequest,
  CreateSelfDiagnosisRequestInput,
  UpdateSelfDiagnosisRequestInput,
  SelfDiagnosisRequestByYearMonthAndTimestampQueryVariables,
  ModelSortDirection,
} from "../../graphql/API";
import {
  createSelfDiagnosisRequest,
  updateSelfDiagnosisRequest,
} from "../../graphql/mutations";
import {
  getSelfDiagnosisRequest,
  listSelfDiagnosisRequests,
  selfDiagnosisRequestByYearMonthAndTimestamp,
} from "../../graphql/queries";

export default class SelfDiagnosisRequestAPI {
  async get(bookingId: string): Promise<SelfDiagnosisRequest> {
    const response: any = await API.graphql(
      graphqlOperation(getSelfDiagnosisRequest, {
        bookingId,
      })
    );

    return response.data.getSelfDiagnosisRequest;
  }

  async list(shopId: string): Promise<SelfDiagnosisRequest[]> {
    const response: any = await API.graphql(
      graphqlOperation(listSelfDiagnosisRequests, {
        filter: {
          shopId: {
            eq: shopId,
          },
        },
      })
    );

    return response.data.listSelfDiagnosisRequests.items;
  }

  async listByYearMonth(yearMonth: string): Promise<SelfDiagnosisRequest[]> {
    const variables: SelfDiagnosisRequestByYearMonthAndTimestampQueryVariables =
      {
        yearMonth,
        sortDirection: ModelSortDirection.DESC,
      };

    const response: any = await API.graphql({
      query: selfDiagnosisRequestByYearMonthAndTimestamp.replace("owner", ""),
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.selfDiagnosisRequestByYearMonthAndTimestamp.items;
  }

  async create(
    input: CreateSelfDiagnosisRequestInput
  ): Promise<SelfDiagnosisRequest> {
    const response: any = await API.graphql(
      graphqlOperation(createSelfDiagnosisRequest, {
        input,
      })
    );

    return response.data.createSelfDiagnosisRequest;
  }

  async update(input: UpdateSelfDiagnosisRequestInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateSelfDiagnosisRequest, {
        input,
      })
    );
  }
}
