import BookingAPI from "./booking";
import ConsultationSolutionPresetAPI from "./consultation-solution-preset";
import CoupangProductAPI from "./coupang-product";
import EventAPI from "./event";
import ShopServiceDrinkAPI from "./shop-service-drink";

export default class APIGateway {
  consultationSolutionPreset: ConsultationSolutionPresetAPI =
    new ConsultationSolutionPresetAPI();
  booking: BookingAPI = new BookingAPI();
  coupangProduct: CoupangProductAPI = new CoupangProductAPI();
  shopServiceDrink: ShopServiceDrinkAPI = new ShopServiceDrinkAPI();
  event: EventAPI = new EventAPI();
}
