import { Routes, Route } from "react-router-dom";
import App from "../../App";
import Home from "../../components/Home";
import ConsultationList from "../../components/Consultation";
import User from "../../components/User";
import BookingList from "../../components/Booking";
import ShopList from "../../components/Shop";
import SalesStatus from "../../components/SalesStatus";
import DesignerList from "../../components/Designer";
import NotFound404 from "../../components/NotFound404";
import FeedbackList from "../../components/Feedback";
import ConsultationSolutionPreset from "../../components/ConsultationSolutionPreset";
import CoupangProductList from "../../components/CoupangProduct";
import ConsultationRequestList from "../../components/ConsultationRequest";
import RegularCustomerNoteList from "../../components/RegularCustomerNote";
import ShopServiceDrinkList from "../../components/ShopServiceDrink";
import SelfDiagnosisRequestList from "../../components/SelfDiagnosisRequest";
import CaringList from "../../components/Caring";
import EventList from "../../components/Event";
import CustomerList from "../../components/Customer";
import ConsultationRequestDetails from "../../components/ConsultationRequestDetails";

export default function RouteList() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="consultation" element={<ConsultationList />} />
        <Route path="customer" element={<CustomerList />} />
        <Route path="booking" element={<BookingList />} />
        <Route path="user" element={<User />} />
        <Route path="shop" element={<ShopList />} />
        <Route path="designer" element={<DesignerList />} />
        <Route path="sales-status" element={<SalesStatus />} />
        <Route path="feedback" element={<FeedbackList />} />
        <Route
          path="regular-customer-note"
          element={<RegularCustomerNoteList />}
        />
        <Route
          path="consultation-solution-preset"
          element={<ConsultationSolutionPreset />}
        />
        <Route path="coupang-product" element={<CoupangProductList />} />
        <Route
          path="consultation-request"
          element={<ConsultationRequestList />}
        />
        <Route
          path="consultation-request-details/:id"
          element={<ConsultationRequestDetails />}
        />
        <Route path="shop-service-drink" element={<ShopServiceDrinkList />} />
        <Route
          path="self-diagnosis-request"
          element={<SelfDiagnosisRequestList />}
        />
        <Route path="caring" element={<CaringList />} />
        <Route path="event" element={<EventList />} />
        <Route path="*" element={<NotFound404 />} />
      </Route>
    </Routes>
  );
}
