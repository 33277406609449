import { API } from "aws-amplify";
import {
  CreateDesignerInput,
  Designer,
  UpdateDesignerInput,
} from "../../graphql/API";
import { createDesigner, updateDesigner } from "../../graphql/mutations";
import { designerByShopId } from "../../graphql/queries";

export default class DesignerAPI {
  async listDesigners(shopId: string): Promise<Designer[]> {
    const response: any = await API.graphql({
      query: designerByShopId,
      variables: { shopId },
    });
    return response.data.designerByShopId.items;
  }

  async createDesigner(input: CreateDesignerInput) {
    return API.graphql({
      query: createDesigner,
      variables: {
        input,
      },
    });
  }

  async updateDesigner(input: UpdateDesignerInput) {
    return API.graphql({
      query: updateDesigner,
      variables: {
        input,
      },
    });
  }
}
