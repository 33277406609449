import { Stack } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CaringAPI from "../../api/caring";
import { Caring } from "../../graphql/API";

export default function CaringList() {
  const dispatch = useDispatch();

  const caringAPI = new CaringAPI();

  const [items, setItems] = useState<Caring[]>([]);

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    const data = await caringAPI.listByYearMonth(dayjs().format("YYYYMM"));
    setItems(data);
  }

  const columns: GridColumns<Caring> = [
    {
      field: "designerName",
      headerName: "디자이너 이름",
      valueGetter(params) {
        return params.row.designerName;
      },
    },
    {
      field: "shopName",
      headerName: "매장",
      flex: 1,
      valueGetter(params) {
        return params.row.shopName;
      },
    },
    {
      field: "date",
      headerName: "생성일",
      minWidth: 120,
      valueGetter(params) {
        return params.value;
      },
    },
    {
      field: "name",
      headerName: "고객 이름",
      flex: 1.5,
      valueGetter(params) {
        return params.row.name;
      },
    },
    {
      field: "pointOfToday",
      headerName: "오늘 시술 포인트",
      flex: 1.5,
      valueGetter(params) {
        return params.row.pointOfToday;
      },
    },
    {
      field: "nextVisitDate",
      headerName: "다음 방문 일자",
      flex: 1.5,
      valueGetter(params) {
        return params.row.nextVisitDate;
      },
    },
  ];

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>APP 케어링 발송 기록</h4>
            <DataGridPro
              columns={columns}
              rows={items.map((item) => {
                return {
                  ...item,
                };
              })}
              autoHeight={true}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ textAlign: "center" }}
                  >
                    데이터가 없습니다.
                  </Stack>
                ),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
