import { Stack } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { API, Auth } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Container, Row, Col, Table, Badge, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setShowSpinner, setShowUserModal } from "../../features/common";
import UserModal from "./Modal";

export type CognitoUser = {
  Username: string;
  Attributes: {
    Name: string;
    Value: string;
  }[];
  UserCreateDate: string;
  UserLastModifiedDate: string;
  Groups: {
    GroupName: string;
  }[];
};

const pageSize = 60;

export default function User() {
  const dispatch = useDispatch();

  const [users, setUsers] = useState<CognitoUser[]>([]);
  const [user, setUser] = useState<CognitoUser>();
  const [groups, setGroups] = useState<any[]>([]);
  const [groupName, setGroupName] = useState<string>();
  const [nextToken, setNextToken] = useState<string>();

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (groupName) {
      setNextToken(undefined);
      fetchUsers(groupName);
    }
  }, [groupName]);

  async function fetchUsers(groupName: string) {
    dispatch(setShowSpinner(true));
    const result = await listUsersInGroup(groupName, 60);

    setUsers(
      result.map((user: any) => {
        return {
          ...user,
          Groups: [
            {
              GroupName: groupName,
            },
          ],
        };
      })
    );
    dispatch(setShowSpinner(false));
  }

  async function initialize() {
    const groups = await listGroups(10);

    setGroups(groups);
  }

  function showUserModal(user: CognitoUser) {
    setUser(user);
    dispatch(setShowUserModal(true));
  }

  async function listGroups(limit: number) {
    let apiName = "AdminQueries";
    let path = "/listGroups";
    let myInit = {
      queryStringParameters: {
        limit: limit,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);

    return rest.Groups;
  }

  async function listUsersInGroup(groupname: string, limit: number) {
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname,
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    setNextToken(NextToken);

    return rest.Users;
  }

  async function addUserToGroup(username: string, groupname: string) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username,
        groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    await API.post(apiName, path, myInit);
  }

  async function onClickAddUserToGroup(
    username: string,
    email: string,
    groupname: string
  ) {
    if (!confirm(`${email} 계정을 ${groupname} 그룹에 추가할까요?`)) {
      return;
    }

    await addUserToGroup(username, groupname);

    initialize();
  }

  const columns: GridColumns<CognitoUser> = [
    {
      field: "email",
      headerName: "이메일",
      flex: 1.5,
      valueGetter(params) {
        return params.row.Attributes.find((a) => a.Name === "email")?.Value;
      },
    },
    {
      field: "designerName",
      headerName: "이름",
      valueGetter(params) {
        return params.row.Attributes.find(
          (a) => a.Name === "custom:designer_name"
        )?.Value;
      },
    },
    {
      field: "shopName",
      headerName: "매장",
      flex: 1,
      valueGetter(params) {
        return params.row.Attributes.find((a) => a.Name === "custom:shop_name")
          ?.Value;
      },
    },
    {
      field: "groupName",
      headerName: "그룹",
      valueGetter(params) {
        return params.row.Groups[0].GroupName;
      },
    },
    {
      field: "businessId",
      headerName: "네이버 업체 고유 ID",
      valueGetter(params) {
        return params.row.Attributes.find(
          (a) => a.Name === "custom:business_id"
        )?.Value;
      },
    },
    {
      field: "bizItemId",
      headerName: "네이버 상품 고유 ID",
      valueGetter(params) {
        return params.row.Attributes.find(
          (a) => a.Name === "custom:biz_item_id"
        )?.Value;
      },
    },
    {
      field: "UserCreateDate",
      headerName: "생성일",
      minWidth: 120,
      valueGetter(params) {
        return dayjs(params.value).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      field: "UserLastModifiedDate",
      headerName: "변경일",
      minWidth: 120,
      valueGetter(params) {
        return params.value
          ? dayjs(params.value).format("YYYY-MM-DD HH:mm")
          : "";
      },
    },
    {
      field: "lastAppearance",
      headerName: "최근 접속",
      flex: 1,
      valueGetter(params) {
        return params.row.Attributes.find(
          (a) => a.Name === "custom:last_appearance"
        )?.Value;
      },
    },
    {
      field: "action",
      headerName: "액션",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            onClick={() => {
              showUserModal(params.row);
            }}
          >
            상세
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>사용자 관리</h4>
            <div style={{ marginBottom: 10 }}>
              {groups.map((group) => {
                return (
                  <Button
                    key={group.GroupName}
                    variant={
                      group.GroupName === groupName
                        ? "primary"
                        : "outline-primary"
                    }
                    size="sm"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      setGroupName(group.GroupName);
                    }}
                  >
                    {group.GroupName}
                  </Button>
                );
              })}
            </div>
            <DataGridPro
              columns={columns}
              rows={users.map((item) => {
                return {
                  ...item,
                  id: item.Username,
                };
              })}
              autoHeight={true}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ textAlign: "center" }}
                  >
                    데이터가 없습니다.
                  </Stack>
                ),
              }}
            />
            {!groupName && <>그룹을 선택하세요.</>}

            <UserModal user={user} />
            <Button
              size="sm"
              variant="outline-primary"
              style={{ marginTop: 5 }}
              onClick={() => {
                if (nextToken && groupName) fetchUsers(groupName);
              }}
            >
              Load more
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
