import { API } from "aws-amplify";
import { toast } from "react-toastify";
import {
  CreateConsultationSolutionPresetInput,
  ConsultationSolutionPreset,
  UpdateConsultationSolutionPresetInput,
  DeleteConsultationSolutionPresetInput,
} from "../../graphql/API";
import {
  createConsultationSolutionPreset,
  deleteConsultationSolutionPreset,
  updateConsultationSolutionPreset,
} from "../../graphql/mutations";
import { listConsultationSolutionPresets } from "../../graphql/queries";

export default class ConsultationSolutionPresetAPI {
  async list(): Promise<ConsultationSolutionPreset[]> {
    const response: any = await API.graphql({
      query: listConsultationSolutionPresets,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.listConsultationSolutionPresets.items.filter(
      (item: ConsultationSolutionPreset) => !item._deleted
    );
  }

  async getByCategoryAndSentence(category: string, sentence: string) {
    const response: any = await API.graphql({
      query: listConsultationSolutionPresets,
      variables: {
        filter: {
          category: {
            eq: category,
          },
          sentence: {
            eq: sentence,
          },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const items = response.data.listConsultationSolutionPresets.items;

    return items.length > 0 ? items[0] : undefined;
  }

  async create(input: CreateConsultationSolutionPresetInput) {
    const exist = await this.getByCategoryAndSentence(
      input.category,
      input.sentence
    );

    if (exist) {
      toast.warn("중복 데이터입니다.");
      return;
    }

    return API.graphql({
      query: createConsultationSolutionPreset,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async update(input: UpdateConsultationSolutionPresetInput) {
    return API.graphql({
      query: updateConsultationSolutionPreset,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async delete(input: DeleteConsultationSolutionPresetInput) {
    return API.graphql({
      query: deleteConsultationSolutionPreset,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
