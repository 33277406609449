export default function Footer() {
  return (
    <div className="text-center" style={{ paddingTop: 20, paddingBottom: 20 }}>
      <small>
        나나로그 솔루션
        <br />
        Copyright © 2022 Future Beauty Inc. 모든 권리 보유.
        <br />
        <p style={{ color: "#666" }}>
          (주)퓨처뷰티 대표이사 박제희
          <br />
          주소: 서울특별시 강남구 역삼로 165, 7층 이, 에프호
          <br />
          사업자등록번호: 527-88-01574
          <br />
          통신판매업신고번호: 제 2022-서울강남-02708호
        </p>
      </small>
    </div>
  );
}
