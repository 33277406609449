/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDiscountPreset = /* GraphQL */ `
  query GetDiscountPreset($id: ID!) {
    getDiscountPreset(id: $id) {
      id
      shopId
      name
      amount
      rate
      type
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDiscountPresets = /* GraphQL */ `
  query ListDiscountPresets(
    $filter: ModelDiscountPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountPresets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        name
        amount
        rate
        type
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDiscountPresets = /* GraphQL */ `
  query SyncDiscountPresets(
    $filter: ModelDiscountPresetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscountPresets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopId
        name
        amount
        rate
        type
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const discountPresetByShopId = /* GraphQL */ `
  query DiscountPresetByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountPresetByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        amount
        rate
        type
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDesigner = /* GraphQL */ `
  query GetDesigner($id: ID!) {
    getDesigner(id: $id) {
      id
      shopId
      name
      naverName
      businessId
      bizItemId
      phoneNumber
      imageUrl
      s3Key
      userId
      youtubeUrl
      instagramUrl
      inactive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDesigners = /* GraphQL */ `
  query ListDesigners(
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesigners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDesigners = /* GraphQL */ `
  query SyncDesigners(
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDesigners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const designerByShopId = /* GraphQL */ `
  query DesignerByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    designerByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const designerByBusinessId = /* GraphQL */ `
  query DesignerByBusinessId(
    $businessId: String!
    $bizItemId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    designerByBusinessId(
      businessId: $businessId
      bizItemId: $bizItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const designerByBizItemId = /* GraphQL */ `
  query DesignerByBizItemId(
    $bizItemId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    designerByBizItemId(
      bizItemId: $bizItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const designerByUserId = /* GraphQL */ `
  query DesignerByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDesignerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    designerByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        naverName
        businessId
        bizItemId
        phoneNumber
        imageUrl
        s3Key
        userId
        youtubeUrl
        instagramUrl
        inactive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($bookingId: ID!) {
    getFeedback(bookingId: $bookingId) {
      bookingId
      category
      shopId
      shopName
      designerName
      designerId
      date
      satisfaction
      comment
      supportMessage
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $bookingId: ID
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbacks(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeedbacks = /* GraphQL */ `
  query SyncFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackByShopId = /* GraphQL */ `
  query FeedbackByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackByDesignerName = /* GraphQL */ `
  query FeedbackByDesignerName(
    $designerName: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByDesignerName(
      designerName: $designerName
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackByDesignerId = /* GraphQL */ `
  query FeedbackByDesignerId(
    $designerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByDesignerId(
      designerId: $designerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackByDate = /* GraphQL */ `
  query FeedbackByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        category
        shopId
        shopName
        designerName
        designerId
        date
        satisfaction
        comment
        supportMessage
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRegularCustomerNote = /* GraphQL */ `
  query GetRegularCustomerNote($bookingId: ID!) {
    getRegularCustomerNote(bookingId: $bookingId) {
      bookingId
      shopId
      shopName
      customerId
      customerName
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRegularCustomerNotes = /* GraphQL */ `
  query ListRegularCustomerNotes(
    $bookingId: ID
    $filter: ModelRegularCustomerNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegularCustomerNotes(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        shopId
        shopName
        customerId
        customerName
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRegularCustomerNotes = /* GraphQL */ `
  query SyncRegularCustomerNotes(
    $filter: ModelRegularCustomerNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegularCustomerNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        bookingId
        shopId
        shopName
        customerId
        customerName
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserPushTokenRequest = /* GraphQL */ `
  query GetUserPushTokenRequest($id: ID!) {
    getUserPushTokenRequest(id: $id) {
      id
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserPushTokenRequests = /* GraphQL */ `
  query ListUserPushTokenRequests(
    $filter: ModelUserPushTokenRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPushTokenRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserPushTokenRequests = /* GraphQL */ `
  query SyncUserPushTokenRequests(
    $filter: ModelUserPushTokenRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPushTokenRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userPushTokenRequestByOwner = /* GraphQL */ `
  query UserPushTokenRequestByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserPushTokenRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPushTokenRequestByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserPushToken = /* GraphQL */ `
  query GetUserPushToken($token: String!) {
    getUserPushToken(token: $token) {
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserPushTokens = /* GraphQL */ `
  query ListUserPushTokens(
    $token: String
    $filter: ModelUserPushTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserPushTokens(
      token: $token
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserPushTokens = /* GraphQL */ `
  query SyncUserPushTokens(
    $filter: ModelUserPushTokenFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPushTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userPushTokenByOwner = /* GraphQL */ `
  query UserPushTokenByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserPushTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userPushTokenByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        token
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCaring = /* GraphQL */ `
  query GetCaring($id: ID!) {
    getCaring(id: $id) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      timestamp
      pointOfToday
      aftercareTips
      stylingTips
      precautions
      serviceRecommendation
      nextVisitDate
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCarings = /* GraphQL */ `
  query ListCarings(
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCarings = /* GraphQL */ `
  query SyncCarings(
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCarings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const caringByOwnerAndYearMonth = /* GraphQL */ `
  query CaringByOwnerAndYearMonth(
    $owner: String!
    $yearMonth: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caringByOwnerAndYearMonth(
      owner: $owner
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const caringByOwnerAndTimestamp = /* GraphQL */ `
  query CaringByOwnerAndTimestamp(
    $owner: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caringByOwnerAndTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const caringByYearMonth = /* GraphQL */ `
  query CaringByYearMonth(
    $yearMonth: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caringByYearMonth(
      yearMonth: $yearMonth
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const caringByDate = /* GraphQL */ `
  query CaringByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    caringByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        timestamp
        pointOfToday
        aftercareTips
        stylingTips
        precautions
        serviceRecommendation
        nextVisitDate
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSelfDiagnosisRequest = /* GraphQL */ `
  query GetSelfDiagnosisRequest($id: ID!) {
    getSelfDiagnosisRequest(id: $id) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSelfDiagnosisRequests = /* GraphQL */ `
  query ListSelfDiagnosisRequests(
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelfDiagnosisRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSelfDiagnosisRequests = /* GraphQL */ `
  query SyncSelfDiagnosisRequests(
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSelfDiagnosisRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisRequestByOwner = /* GraphQL */ `
  query SelfDiagnosisRequestByOwner(
    $owner: String!
    $yearMonth: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisRequestByOwner(
      owner: $owner
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisRequestByOwnerAndTimestamp = /* GraphQL */ `
  query SelfDiagnosisRequestByOwnerAndTimestamp(
    $owner: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisRequestByOwnerAndTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisRequestByYearMonthAndTimestamp = /* GraphQL */ `
  query SelfDiagnosisRequestByYearMonthAndTimestamp(
    $yearMonth: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisRequestByYearMonthAndTimestamp(
      yearMonth: $yearMonth
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisRequestByDate = /* GraphQL */ `
  query SelfDiagnosisRequestByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisRequestByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSelfDiagnosisContent = /* GraphQL */ `
  query GetSelfDiagnosisContent($requestId: ID!) {
    getSelfDiagnosisContent(requestId: $requestId) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSelfDiagnosisContents = /* GraphQL */ `
  query ListSelfDiagnosisContents(
    $requestId: ID
    $filter: ModelSelfDiagnosisContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelfDiagnosisContents(
      requestId: $requestId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSelfDiagnosisContents = /* GraphQL */ `
  query SyncSelfDiagnosisContents(
    $filter: ModelSelfDiagnosisContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSelfDiagnosisContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisContentByYearMonth = /* GraphQL */ `
  query SelfDiagnosisContentByYearMonth(
    $yearMonth: String!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisContentByYearMonth(
      yearMonth: $yearMonth
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisContentByDate = /* GraphQL */ `
  query SelfDiagnosisContentByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisContentByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRegularCustomerQuestionnaireRequest = /* GraphQL */ `
  query GetRegularCustomerQuestionnaireRequest($id: ID!) {
    getRegularCustomerQuestionnaireRequest(id: $id) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRegularCustomerQuestionnaireRequests = /* GraphQL */ `
  query ListRegularCustomerQuestionnaireRequests(
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegularCustomerQuestionnaireRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRegularCustomerQuestionnaireRequests = /* GraphQL */ `
  query SyncRegularCustomerQuestionnaireRequests(
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegularCustomerQuestionnaireRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireRequestByOwner = /* GraphQL */ `
  query RegularCustomerQuestionnaireRequestByOwner(
    $owner: String!
    $yearMonth: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireRequestByOwner(
      owner: $owner
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireRequestByOwnerAndTimestamp = /* GraphQL */ `
  query RegularCustomerQuestionnaireRequestByOwnerAndTimestamp(
    $owner: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireRequestByOwnerAndTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireRequestByYearMonthAndTimestamp = /* GraphQL */ `
  query RegularCustomerQuestionnaireRequestByYearMonthAndTimestamp(
    $yearMonth: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireRequestByYearMonthAndTimestamp(
      yearMonth: $yearMonth
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireRequestByDate = /* GraphQL */ `
  query RegularCustomerQuestionnaireRequestByDate(
    $date: AWSDate!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireRequestByDate(
      date: $date
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        name
        phoneNumber
        yearMonth
        date
        sentAt
        writtenAt
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRegularCustomerQuestionnaireContent = /* GraphQL */ `
  query GetRegularCustomerQuestionnaireContent($requestId: ID!) {
    getRegularCustomerQuestionnaireContent(requestId: $requestId) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      treatmentsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRegularCustomerQuestionnaireContents = /* GraphQL */ `
  query ListRegularCustomerQuestionnaireContents(
    $requestId: ID
    $filter: ModelRegularCustomerQuestionnaireContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegularCustomerQuestionnaireContents(
      requestId: $requestId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        treatmentsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRegularCustomerQuestionnaireContents = /* GraphQL */ `
  query SyncRegularCustomerQuestionnaireContents(
    $filter: ModelRegularCustomerQuestionnaireContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegularCustomerQuestionnaireContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        treatmentsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireContentByYearMonth = /* GraphQL */ `
  query RegularCustomerQuestionnaireContentByYearMonth(
    $yearMonth: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireContentByYearMonth(
      yearMonth: $yearMonth
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        treatmentsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const regularCustomerQuestionnaireContentByDate = /* GraphQL */ `
  query RegularCustomerQuestionnaireContentByDate(
    $date: AWSDate!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegularCustomerQuestionnaireContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regularCustomerQuestionnaireContentByDate(
      date: $date
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        requestId
        shopName
        designerName
        name
        yearMonth
        date
        levelOfStyleChange
        nextSchedule
        comment
        productsInterestedIn
        treatmentsInterestedIn
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        timestamp
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserActivitySnapshot = /* GraphQL */ `
  query GetUserActivitySnapshot($id: String!) {
    getUserActivitySnapshot(id: $id) {
      id
      owner
      dailyLogCount
      selfDiagnosisCount
      regularCustomerQuestionnaireCount
      caringCount
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserActivitySnapshots = /* GraphQL */ `
  query ListUserActivitySnapshots(
    $id: String
    $filter: ModelUserActivitySnapshotFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserActivitySnapshots(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        dailyLogCount
        selfDiagnosisCount
        regularCustomerQuestionnaireCount
        caringCount
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserActivitySnapshots = /* GraphQL */ `
  query SyncUserActivitySnapshots(
    $filter: ModelUserActivitySnapshotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserActivitySnapshots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        dailyLogCount
        selfDiagnosisCount
        regularCustomerQuestionnaireCount
        caringCount
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDailyLog = /* GraphQL */ `
  query GetDailyLog($id: String!) {
    getDailyLog(id: $id) {
      id
      owner
      shopName
      designerName
      yearMonth
      date
      timestamp
      averageConsultationMinutes
      serviceRecommendationCount
      productRecommendationCount
      prepaidTicketRecommendationCount
      stretch
      drinkingWater
      comment
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDailyLogs = /* GraphQL */ `
  query ListDailyLogs(
    $id: String
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDailyLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDailyLogs = /* GraphQL */ `
  query SyncDailyLogs(
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDailyLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const dailyLogByOwnerAndYearMonth = /* GraphQL */ `
  query DailyLogByOwnerAndYearMonth(
    $owner: String!
    $yearMonth: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogByOwnerAndYearMonth(
      owner: $owner
      yearMonth: $yearMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const dailyLogByOwnerAndTimestamp = /* GraphQL */ `
  query DailyLogByOwnerAndTimestamp(
    $owner: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogByOwnerAndTimestamp(
      owner: $owner
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const dailyLogByYearMonthAndTimestamp = /* GraphQL */ `
  query DailyLogByYearMonthAndTimestamp(
    $yearMonth: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogByYearMonthAndTimestamp(
      yearMonth: $yearMonth
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const dailyLogByDate = /* GraphQL */ `
  query DailyLogByDate(
    $date: AWSDate!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDailyLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dailyLogByDate(
      date: $date
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        shopName
        designerName
        yearMonth
        date
        timestamp
        averageConsultationMinutes
        serviceRecommendationCount
        productRecommendationCount
        prepaidTicketRecommendationCount
        stretch
        drinkingWater
        comment
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSelfDiagnosis = /* GraphQL */ `
  query GetSelfDiagnosis($bookingId: ID!) {
    getSelfDiagnosis(bookingId: $bookingId) {
      bookingId
      customerId
      customerName
      yearMonth
      date
      shopId
      shopName
      designerId
      designerName
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSelfDiagnoses = /* GraphQL */ `
  query ListSelfDiagnoses(
    $bookingId: ID
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelfDiagnoses(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSelfDiagnoses = /* GraphQL */ `
  query SyncSelfDiagnoses(
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSelfDiagnoses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisByCustomerId = /* GraphQL */ `
  query SelfDiagnosisByCustomerId(
    $customerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisByCustomerId(
      customerId: $customerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisByYearMonthAndShopId = /* GraphQL */ `
  query SelfDiagnosisByYearMonthAndShopId(
    $yearMonth: String!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisByYearMonthAndShopId(
      yearMonth: $yearMonth
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisByDate = /* GraphQL */ `
  query SelfDiagnosisByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisByShopId = /* GraphQL */ `
  query SelfDiagnosisByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const selfDiagnosisByDesignerId = /* GraphQL */ `
  query SelfDiagnosisByDesignerId(
    $designerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSelfDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    selfDiagnosisByDesignerId(
      designerId: $designerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        customerId
        customerName
        yearMonth
        date
        shopId
        shopName
        designerId
        designerName
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        recentDyeing
        recentPerm
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        blackDyedHair
        bleachedHair
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConsultation = /* GraphQL */ `
  query GetConsultation($id: ID!) {
    getConsultation(id: $id) {
      id
      bookingId
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      yearMonth
      date
      shopId
      status
      selfDiagnosisStatus
      mode
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      neckLength
      neckThickness
      shoulderWidth
      shoulderShape
      hairColor
      virginHairColor
      colorfulnessOfHair
      colorfulnessesOfHair
      recentDyeing
      recentFirm
      damageDegreeOfHair
      amountOfHair
      thicknessOfHair
      curlDegreeOfHair
      faceShape
      faceShapes
      frontHeadShape
      sideHeadShape
      solution {
        face
        hair
        needs
        point
        aftercareTips
        stylingTips
        precautions
        recommendedTreatment
        nextVisitDate
      }
      solutionSentAt
      hairRecommendation {
        bangs
        hairLength
        hairVolume
        hairVolumes
        haircut
        haircuts
        hairColorMenu
        hairColorType
        hairFirm
        hairCare
      }
      products {
        title
        url
        imageFilePath
        description
      }
      shopName
      designerName
      designerId
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConsultations = /* GraphQL */ `
  query ListConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConsultations = /* GraphQL */ `
  query SyncConsultations(
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByBookingId = /* GraphQL */ `
  query ConsultationByBookingId(
    $bookingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByBookingId(
      bookingId: $bookingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByCustomerId = /* GraphQL */ `
  query ConsultationByCustomerId(
    $customerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByCustomerId(
      customerId: $customerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByName = /* GraphQL */ `
  query ConsultationByName(
    $name: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByName(
      name: $name
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByYearMonthAndShopId = /* GraphQL */ `
  query ConsultationByYearMonthAndShopId(
    $yearMonth: String!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByYearMonthAndShopId(
      yearMonth: $yearMonth
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByYearMonthAndDesignerId = /* GraphQL */ `
  query ConsultationByYearMonthAndDesignerId(
    $yearMonth: String!
    $designerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByYearMonthAndDesignerId(
      yearMonth: $yearMonth
      designerId: $designerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByDate = /* GraphQL */ `
  query ConsultationByDate(
    $date: AWSDate!
    $designerName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByDate(
      date: $date
      designerName: $designerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByShopId = /* GraphQL */ `
  query ConsultationByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationByDesignerId = /* GraphQL */ `
  query ConsultationByDesignerId(
    $designerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationByDesignerId(
      designerId: $designerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        yearMonth
        date
        shopId
        status
        selfDiagnosisStatus
        mode
        inflowChannel
        gender
        dry
        concerns
        frequencyOfVisits
        outfitStyles
        treatmentsInterestedIn
        productsForShampooing
        productsForStyling
        neckLength
        neckThickness
        shoulderWidth
        shoulderShape
        hairColor
        virginHairColor
        colorfulnessOfHair
        colorfulnessesOfHair
        recentDyeing
        recentFirm
        damageDegreeOfHair
        amountOfHair
        thicknessOfHair
        curlDegreeOfHair
        faceShape
        faceShapes
        frontHeadShape
        sideHeadShape
        solution {
          face
          hair
          needs
          point
          aftercareTips
          stylingTips
          precautions
          recommendedTreatment
          nextVisitDate
        }
        solutionSentAt
        hairRecommendation {
          bangs
          hairLength
          hairVolume
          hairVolumes
          haircut
          haircuts
          hairColorMenu
          hairColorType
          hairFirm
          hairCare
        }
        products {
          title
          url
          imageFilePath
          description
        }
        shopName
        designerName
        designerId
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAnonymousPhoto = /* GraphQL */ `
  query GetAnonymousPhoto($id: ID!) {
    getAnonymousPhoto(id: $id) {
      id
      bookingId
      consultationId
      requestId
      s3Key
      displayOrder
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAnonymousPhotos = /* GraphQL */ `
  query ListAnonymousPhotos(
    $filter: ModelAnonymousPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnonymousPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingId
        consultationId
        requestId
        s3Key
        displayOrder
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnonymousPhotos = /* GraphQL */ `
  query SyncAnonymousPhotos(
    $filter: ModelAnonymousPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnonymousPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bookingId
        consultationId
        requestId
        s3Key
        displayOrder
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const anonymousPhotoByBookingId = /* GraphQL */ `
  query AnonymousPhotoByBookingId(
    $bookingId: ID!
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnonymousPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anonymousPhotoByBookingId(
      bookingId: $bookingId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        consultationId
        requestId
        s3Key
        displayOrder
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const anonymousPhotoByConsultationId = /* GraphQL */ `
  query AnonymousPhotoByConsultationId(
    $consultationId: ID!
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnonymousPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anonymousPhotoByConsultationId(
      consultationId: $consultationId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        consultationId
        requestId
        s3Key
        displayOrder
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const anonymousPhotoByRequestId = /* GraphQL */ `
  query AnonymousPhotoByRequestId(
    $requestId: ID!
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnonymousPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anonymousPhotoByRequestId(
      requestId: $requestId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        consultationId
        requestId
        s3Key
        displayOrder
        category
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      bookingId
      s3Key
      displayOrder
      identityId
      description
      category
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingId
        s3Key
        displayOrder
        identityId
        description
        category
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPhotos = /* GraphQL */ `
  query SyncPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bookingId
        s3Key
        displayOrder
        identityId
        description
        category
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const photoByBookingId = /* GraphQL */ `
  query PhotoByBookingId(
    $bookingId: ID!
    $displayOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photoByBookingId(
      bookingId: $bookingId
      displayOrder: $displayOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bookingId
        s3Key
        displayOrder
        identityId
        description
        category
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($bookingId: ID!) {
    getCheckIn(bookingId: $bookingId) {
      bookingId
      shopId
      checkInRequests
      drink
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $bookingId: ID
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckIns(
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        bookingId
        shopId
        checkInRequests
        drink
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCheckIns = /* GraphQL */ `
  query SyncCheckIns(
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCheckIns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        bookingId
        shopId
        checkInRequests
        drink
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const checkInByShopId = /* GraphQL */ `
  query CheckInByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
  ) {
    checkInByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bookingId
        shopId
        checkInRequests
        drink
        modelVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      yearMonth
      date
      time
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      phoneNumber
      consultationId
      consultation {
        status
        selfDiagnosisStatus
        solutionSentAt
        inflowChannel
        hasPhotos
      }
      hasSelfDiagnosis
      hasRegularCustomerNote
      designerName
      designerId
      optionName
      shopId
      shopName
      naverBookingNumber
      bookingStatus
      serviceStatus
      paymentStatus
      requestMessage
      designerMemo
      naverSnapshot {
        price
        bizItemPrice
        onsitePrice
        email
        isOnsitePayment
      }
      naverOptions {
        categoryName
        name
        originalName
        desc
        price
      }
      naverPayments {
        method
        moment
        provider
        paymentId
        bookingId
        amount
        providerDiscountAmount
        regDateTime
        status
        statusHistories {
          status
          dateTime
        }
      }
      initialPrice
      finalPrice
      totalServiceSales
      totalProductSales
      initialDeposit
      paidAmount
      paidInCashAmount
      paidByCardAmount
      paidByPrepaidPassAmount
      paidByNaverPayAmount
      paidAt
      paymentMemo
      checkInRequests
      drink
      checkedInAt
      origin
      options {
        categoryName
        name
        originalPrice
        discount
        discountRate
        price
        discountPreset {
          id
          shopId
          name
          amount
          rate
          type
        }
      }
      productOptions {
        name
        price
        quantity
        product {
          id
          name
          shopId
          brandId
          brandName
          costPrice
          sellingPrice
        }
      }
      signature
      signedAt
      recentNaverDataReflection
      selfDiagnosisSentAt
      feedbackRequestSentAt
      hasFeedback
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      hasDesignCompletionPhotos
      designCompletionPhotos {
        s3Key
        displayOrder
      }
      signatureOfPortraitRights
      signedAtOfPortraitRights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByYearMonth = /* GraphQL */ `
  query BookingByYearMonth(
    $yearMonth: String!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByYearMonth(
      yearMonth: $yearMonth
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByDate = /* GraphQL */ `
  query BookingByDate(
    $date: AWSDate!
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDate(
      date: $date
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByCustomerId = /* GraphQL */ `
  query BookingByCustomerId(
    $customerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByCustomerId(
      customerId: $customerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByName = /* GraphQL */ `
  query BookingByName(
    $name: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByName(
      name: $name
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByPhoneNumber = /* GraphQL */ `
  query BookingByPhoneNumber(
    $phoneNumber: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByPhoneNumber(
      phoneNumber: $phoneNumber
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByConsultationId = /* GraphQL */ `
  query BookingByConsultationId(
    $consultationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByConsultationId(
      consultationId: $consultationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByDesignerName = /* GraphQL */ `
  query BookingByDesignerName(
    $designerName: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDesignerName(
      designerName: $designerName
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByDesignerId = /* GraphQL */ `
  query BookingByDesignerId(
    $designerId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByDesignerId(
      designerId: $designerId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByShopId = /* GraphQL */ `
  query BookingByShopId(
    $shopId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByShopId(
      shopId: $shopId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByNaverBookingNumber = /* GraphQL */ `
  query BookingByNaverBookingNumber(
    $naverBookingNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByNaverBookingNumber(
      naverBookingNumber: $naverBookingNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        date
        time
        customer {
          phoneNumber
          name
          id
          shopIds
        }
        customerId
        name
        phoneNumber
        consultationId
        consultation {
          status
          selfDiagnosisStatus
          solutionSentAt
          inflowChannel
          hasPhotos
        }
        hasSelfDiagnosis
        hasRegularCustomerNote
        designerName
        designerId
        optionName
        shopId
        shopName
        naverBookingNumber
        bookingStatus
        serviceStatus
        paymentStatus
        requestMessage
        designerMemo
        naverSnapshot {
          price
          bizItemPrice
          onsitePrice
          email
          isOnsitePayment
        }
        naverOptions {
          categoryName
          name
          originalName
          desc
          price
        }
        naverPayments {
          method
          moment
          provider
          paymentId
          bookingId
          amount
          providerDiscountAmount
          regDateTime
          status
          statusHistories {
            status
            dateTime
          }
        }
        initialPrice
        finalPrice
        totalServiceSales
        totalProductSales
        initialDeposit
        paidAmount
        paidInCashAmount
        paidByCardAmount
        paidByPrepaidPassAmount
        paidByNaverPayAmount
        paidAt
        paymentMemo
        checkInRequests
        drink
        checkedInAt
        origin
        options {
          categoryName
          name
          originalPrice
          discount
          discountRate
          price
          discountPreset {
            id
            shopId
            name
            amount
            rate
            type
          }
        }
        productOptions {
          name
          price
          quantity
          product {
            id
            name
            shopId
            brandId
            brandName
            costPrice
            sellingPrice
          }
        }
        signature
        signedAt
        recentNaverDataReflection
        selfDiagnosisSentAt
        feedbackRequestSentAt
        hasFeedback
        hasPhotos
        photos {
          s3Key
          displayOrder
        }
        hasDesignCompletionPhotos
        designCompletionPhotos {
          s3Key
          displayOrder
        }
        signatureOfPortraitRights
        signedAtOfPortraitRights
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      phoneNumber
      lastFourPhoneNumbers
      firstVisitDate
      occupation
      drink
      dress
      trouble
      memo
      bookingCount
      consultationCount
      consultationDoneCount
      signature
      totalRevenue
      vehicleNumber
      shopId
      shopIds
      shopIdsString
      createdAt
      prepaidPassBalance
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByNameAndPhone = /* GraphQL */ `
  query CustomerByNameAndPhone(
    $name: String!
    $phoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByNameAndPhone(
      name: $name
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByShopIdAndName = /* GraphQL */ `
  query CustomerByShopIdAndName(
    $shopId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByShopIdAndName(
      shopId: $shopId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByShopIdAndPhoneNumber = /* GraphQL */ `
  query CustomerByShopIdAndPhoneNumber(
    $shopId: ID!
    $phoneNumber: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByShopIdAndPhoneNumber(
      shopId: $shopId
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByShopIdAndLastFourPhoneNumbers = /* GraphQL */ `
  query CustomerByShopIdAndLastFourPhoneNumbers(
    $shopId: ID!
    $lastFourPhoneNumbers: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByShopIdAndLastFourPhoneNumbers(
      shopId: $shopId
      lastFourPhoneNumbers: $lastFourPhoneNumbers
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phoneNumber
        lastFourPhoneNumbers
        firstVisitDate
        occupation
        drink
        dress
        trouble
        memo
        bookingCount
        consultationCount
        consultationDoneCount
        signature
        totalRevenue
        vehicleNumber
        shopId
        shopIds
        shopIdsString
        createdAt
        prepaidPassBalance
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      owner
      ownerName
      ownerPhoneNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        creatorId
        usePreSelfDiagnosis
        useFeedbackRequest
        owner
        ownerName
        ownerPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShops = /* GraphQL */ `
  query SyncShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        creatorId
        usePreSelfDiagnosis
        useFeedbackRequest
        owner
        ownerName
        ownerPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopByName = /* GraphQL */ `
  query ShopByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        creatorId
        usePreSelfDiagnosis
        useFeedbackRequest
        owner
        ownerName
        ownerPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopByNaverBusinessId = /* GraphQL */ `
  query ShopByNaverBusinessId(
    $naverBusinessId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopByNaverBusinessId(
      naverBusinessId: $naverBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        creatorId
        usePreSelfDiagnosis
        useFeedbackRequest
        owner
        ownerName
        ownerPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopByCreatorId = /* GraphQL */ `
  query ShopByCreatorId(
    $creatorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopByCreatorId(
      creatorId: $creatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        creatorId
        usePreSelfDiagnosis
        useFeedbackRequest
        owner
        ownerName
        ownerPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getShopServiceDrink = /* GraphQL */ `
  query GetShopServiceDrink($id: ID!) {
    getShopServiceDrink(id: $id) {
      id
      name
      shopId
      outOfStock
      displayOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listShopServiceDrinks = /* GraphQL */ `
  query ListShopServiceDrinks(
    $filter: ModelShopServiceDrinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopServiceDrinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopId
        outOfStock
        displayOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShopServiceDrinks = /* GraphQL */ `
  query SyncShopServiceDrinks(
    $filter: ModelShopServiceDrinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShopServiceDrinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shopId
        outOfStock
        displayOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopServiceDrinkByShopId = /* GraphQL */ `
  query ShopServiceDrinkByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopServiceDrinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopServiceDrinkByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopId
        outOfStock
        displayOrder
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getShopUserApplication = /* GraphQL */ `
  query GetShopUserApplication($id: ID!) {
    getShopUserApplication(id: $id) {
      id
      shopId
      userId
      phoneNumber
      completed
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listShopUserApplications = /* GraphQL */ `
  query ListShopUserApplications(
    $filter: ModelShopUserApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopUserApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        phoneNumber
        completed
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShopUserApplications = /* GraphQL */ `
  query SyncShopUserApplications(
    $filter: ModelShopUserApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShopUserApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopId
        userId
        phoneNumber
        completed
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopUserApplicationByShopId = /* GraphQL */ `
  query ShopUserApplicationByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopUserApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopUserApplicationByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        phoneNumber
        completed
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopUserApplicationByUserId = /* GraphQL */ `
  query ShopUserApplicationByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopUserApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopUserApplicationByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        phoneNumber
        completed
        name
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getShopUserRemovalRequest = /* GraphQL */ `
  query GetShopUserRemovalRequest($designerId: ID!) {
    getShopUserRemovalRequest(designerId: $designerId) {
      designerId
      shopId
      userId
      completed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listShopUserRemovalRequests = /* GraphQL */ `
  query ListShopUserRemovalRequests(
    $designerId: ID
    $filter: ModelShopUserRemovalRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShopUserRemovalRequests(
      designerId: $designerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        designerId
        shopId
        userId
        completed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShopUserRemovalRequests = /* GraphQL */ `
  query SyncShopUserRemovalRequests(
    $filter: ModelShopUserRemovalRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShopUserRemovalRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        designerId
        shopId
        userId
        completed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const shopUserRemovalRequestByShopId = /* GraphQL */ `
  query ShopUserRemovalRequestByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShopUserRemovalRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopUserRemovalRequestByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        designerId
        shopId
        userId
        completed
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getServiceOption = /* GraphQL */ `
  query GetServiceOption($shopId: ID!) {
    getServiceOption(shopId: $shopId) {
      shopId
      categories {
        name
        options {
          name
          price
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listServiceOptions = /* GraphQL */ `
  query ListServiceOptions(
    $shopId: ID
    $filter: ModelServiceOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceOptions(
      shopId: $shopId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopId
        categories {
          name
          options {
            name
            price
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServiceOptions = /* GraphQL */ `
  query SyncServiceOptions(
    $filter: ModelServiceOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        shopId
        categories {
          name
          options {
            name
            price
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPrepaidPassPreset = /* GraphQL */ `
  query GetPrepaidPassPreset($id: ID!) {
    getPrepaidPassPreset(id: $id) {
      id
      shopId
      name
      price
      availableAmount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPrepaidPassPresets = /* GraphQL */ `
  query ListPrepaidPassPresets(
    $filter: ModelPrepaidPassPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepaidPassPresets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        price
        availableAmount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPrepaidPassPresets = /* GraphQL */ `
  query SyncPrepaidPassPresets(
    $filter: ModelPrepaidPassPresetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrepaidPassPresets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopId
        name
        price
        availableAmount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const prepaidPassPresetByShopId = /* GraphQL */ `
  query PrepaidPassPresetByShopId(
    $shopId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPrepaidPassPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prepaidPassPresetByShopId(
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        price
        availableAmount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPrepaidPassHistory = /* GraphQL */ `
  query GetPrepaidPassHistory($id: ID!) {
    getPrepaidPassHistory(id: $id) {
      id
      customerId
      shopId
      amount
      bookingId
      type
      description
      referenceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPrepaidPassHistories = /* GraphQL */ `
  query ListPrepaidPassHistories(
    $filter: ModelPrepaidPassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepaidPassHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        shopId
        amount
        bookingId
        type
        description
        referenceId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPrepaidPassHistories = /* GraphQL */ `
  query SyncPrepaidPassHistories(
    $filter: ModelPrepaidPassHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrepaidPassHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerId
        shopId
        amount
        bookingId
        type
        description
        referenceId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const prepaidPassHistoryByCustomerId = /* GraphQL */ `
  query PrepaidPassHistoryByCustomerId(
    $customerId: ID!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPrepaidPassHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prepaidPassHistoryByCustomerId(
      customerId: $customerId
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        shopId
        amount
        bookingId
        type
        description
        referenceId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      shopId
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopId
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const brandByShopId = /* GraphQL */ `
  query BrandByShopId(
    $shopId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandByShopId(
      shopId: $shopId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      shopId
      brandId
      brandName
      costPrice
      sellingPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shopId
        brandId
        brandName
        costPrice
        sellingPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        shopId
        brandId
        brandName
        costPrice
        sellingPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const productByShopId = /* GraphQL */ `
  query ProductByShopId(
    $shopId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByShopId(
      shopId: $shopId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopId
        brandId
        brandName
        costPrice
        sellingPrice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConsultationSolutionPreset = /* GraphQL */ `
  query GetConsultationSolutionPreset($id: ID!) {
    getConsultationSolutionPreset(id: $id) {
      id
      category
      sentence
      priority
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConsultationSolutionPresets = /* GraphQL */ `
  query ListConsultationSolutionPresets(
    $filter: ModelConsultationSolutionPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultationSolutionPresets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        sentence
        priority
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConsultationSolutionPresets = /* GraphQL */ `
  query SyncConsultationSolutionPresets(
    $filter: ModelConsultationSolutionPresetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConsultationSolutionPresets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        category
        sentence
        priority
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const consultationSolutionPresetByCategory = /* GraphQL */ `
  query ConsultationSolutionPresetByCategory(
    $category: String!
    $sentence: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConsultationSolutionPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    consultationSolutionPresetByCategory(
      category: $category
      sentence: $sentence
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        sentence
        priority
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCoupangProduct = /* GraphQL */ `
  query GetCoupangProduct($id: ID!) {
    getCoupangProduct(id: $id) {
      id
      src
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCoupangProducts = /* GraphQL */ `
  query ListCoupangProducts(
    $filter: ModelCoupangProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupangProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        src
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCoupangProducts = /* GraphQL */ `
  query SyncCoupangProducts(
    $filter: ModelCoupangProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCoupangProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        src
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGeneralProduct = /* GraphQL */ `
  query GetGeneralProduct($id: ID!) {
    getGeneralProduct(id: $id) {
      id
      link
      name
      brandName
      description
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGeneralProducts = /* GraphQL */ `
  query ListGeneralProducts(
    $filter: ModelGeneralProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneralProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        link
        name
        brandName
        description
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGeneralProducts = /* GraphQL */ `
  query SyncGeneralProducts(
    $filter: ModelGeneralProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGeneralProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        link
        name
        brandName
        description
        imageUrl
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      hostName
      speakerName
      name
      description
      city
      venue
      link
      yearMonth
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostName
        speakerName
        name
        description
        city
        venue
        link
        yearMonth
        startDate
        endDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hostName
        speakerName
        name
        description
        city
        venue
        link
        yearMonth
        startDate
        endDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const eventByYearMonth = /* GraphQL */ `
  query EventByYearMonth(
    $yearMonth: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByYearMonth(
      yearMonth: $yearMonth
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostName
        speakerName
        name
        description
        city
        venue
        link
        yearMonth
        startDate
        endDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMutationResponse = /* GraphQL */ `
  query GetMutationResponse($id: ID!) {
    getMutationResponse(id: $id) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMutationResponses = /* GraphQL */ `
  query ListMutationResponses(
    $filter: ModelMutationResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMutationResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        success
        reason
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMutationResponses = /* GraphQL */ `
  query SyncMutationResponses(
    $filter: ModelMutationResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMutationResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        success
        reason
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
