import { API } from "aws-amplify";
import { toast } from "react-toastify";
import {
  CreateCoupangProductInput,
  CoupangProduct,
  UpdateCoupangProductInput,
  DeleteCoupangProductInput,
} from "../../graphql/API";
import {
  createCoupangProduct,
  deleteCoupangProduct,
  updateCoupangProduct,
} from "../../graphql/mutations";
import { listCoupangProducts } from "../../graphql/queries";

export default class CoupangProductAPI {
  async list(): Promise<CoupangProduct[]> {
    const response: any = await API.graphql({
      query: listCoupangProducts,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.listCoupangProducts.items.filter(
      (item: CoupangProduct) => !item._deleted
    );
  }

  async create(input: CreateCoupangProductInput) {
    return API.graphql({
      query: createCoupangProduct,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async update(input: UpdateCoupangProductInput) {
    return API.graphql({
      query: updateCoupangProduct,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async delete(input: DeleteCoupangProductInput) {
    return API.graphql({
      query: deleteCoupangProduct,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
