import { API, graphqlOperation } from "aws-amplify";
import {
  RegularCustomerNote,
  CreateRegularCustomerNoteInput,
  UpdateRegularCustomerNoteInput,
} from "../../graphql/API";
import {
  createRegularCustomerNote,
  updateRegularCustomerNote,
} from "../../graphql/mutations";
import {
  getRegularCustomerNote,
  listRegularCustomerNotes,
} from "../../graphql/queries";

export default class RegularCustomerNoteAPI {
  async get(bookingId: string): Promise<RegularCustomerNote> {
    const response: any = await API.graphql(
      graphqlOperation(getRegularCustomerNote, {
        bookingId,
      })
    );

    return response.data.getRegularCustomerNote;
  }

  async list(shopId: string): Promise<RegularCustomerNote[]> {
    const response: any = await API.graphql(
      graphqlOperation(listRegularCustomerNotes, {
        filter: {
          shopId: {
            eq: shopId,
          },
        },
      })
    );

    return response.data.listRegularCustomerNotes.items;
  }

  async create(
    input: CreateRegularCustomerNoteInput
  ): Promise<RegularCustomerNote> {
    const response: any = await API.graphql(
      graphqlOperation(createRegularCustomerNote, {
        input,
      })
    );

    return response.data.createRegularCustomerNote;
  }

  async update(input: UpdateRegularCustomerNoteInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateRegularCustomerNote, {
        input,
      })
    );
  }
}
