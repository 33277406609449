import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import awsExports from "./aws-exports";
import { Amplify, Auth, I18n } from "aws-amplify";
import { LicenseInfo } from "@mui/x-license-pro";
import { Row, Col, Container, Spinner, Alert, Button } from "react-bootstrap";
import { useAppSelector } from "./app/hooks";
import { fetchShops, selectShops } from "./features/shop";
import {
  selectCurrentShopId,
  selectShowSpinner,
  selectUserGroups,
  setCurrentShopId,
  setUserGroups,
} from "./features/common";
import { useDispatch } from "react-redux";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { toast, ToastContainer } from "react-toastify";
import { CognitoUserAmplify } from "@aws-amplify/ui";
import Footer from "./components/Shared/Footer";
import "./App.scss";
import Navigation from "./components/Shared/Navigation";

LicenseInfo.setLicenseKey(
  "83ff619ab4395586d80ff2c926baf2d6Tz01MjA5MCxFPTE2OTY3NDQ0NTYxNDQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

Amplify.configure(awsExports);

const krDict = {
  kr: {
    "Account recovery requires verified contact information":
      "계정 복구를 위해 연락처 확인이 필요합니다",
    "Back to Sign In": "로그인으로 돌아가기",
    "Change Password": "비밀번호 변경하기",
    Changing: "변경중",
    Code: "코드",
    "Confirm Password": "비밀번호 재확인",
    "Confirm Sign Up": "회원가입 확인",
    "Confirm SMS Code": "휴대폰 본인 확인",
    "Confirm TOTP Code": "TOTP 인증번호 확인",
    Confirm: "확인",
    "Confirmation Code": "인증번호",
    Confirming: "확인중",
    "Create a new account": "회원가입",
    "Create Account": "회원가입",
    "Creating Account": "회원가입중",
    "Dismiss alert": "알림 무시",
    Email: "이메일",
    "Enter your code": "인증번호를 입력해주세요",
    "Enter your email": "이메일 입력",
    "Enter your phone number": "전화번호 입력",
    "Enter your username": "아이디를 입력해주세요",
    "Hide password": "비밀번호 숨기기",
    Loading: "로딩중",
    "New password": "새 비밀번호",
    Password: "비밀번호",
    "Phone Number": "전화번호",
    "Resend Code": "인증번호 재전송",
    "Reset your password": "비밀번호 재설정",
    "Reset your Password": "비밀번호 재설정",
    "Send code": "인증코드 보내기",
    "Send Code": "코드 전송",
    Sending: "전송중",
    "Setup TOTP": "TOTP 설정하기",
    "Show password": "비밀번호 보이기",
    "Sign in to your account": "로그인",
    "Sign In with Amazon": "Amazon 로그인",
    "Sign In with Apple": "Apple 로그인",
    "Sign In with Facebook": "Facebook 로그인",
    "Sign In with Google": "Google 로그인",
    "Sign in": "로그인",
    "Sign In": "로그인",
    "Signing in": "로그인중",
    Skip: "다음에 하기",
    Submit: "확인",
    Submitting: "확인중",
    Username: "아이디",
    "Verify Contact": "연락처 확인",
    Verify: "인증",

    // Additional translations provided by customers
    Birthdate: "생년월일",
    "Family Name": "성",
    "Forgot your password?": "비밀번호를 잊으셨나요?",
    "Given Name": "이름",
    Name: "성함",
    Nickname: "닉네임",
    "Preferred Username": "닉네임",
    Profile: "프로필",
    "Reset Password": "비밀번호 재설정",
    Website: "웹사이트",
  },
};

I18n.putVocabularies(krDict);
I18n.setLanguage("kr");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8V9_AEpVjBoo9NeoiouyLBHIF3qD0WBk",
  authDomain: "nanalog.firebaseapp.com",
  projectId: "nanalog",
  storageBucket: "nanalog.appspot.com",
  messagingSenderId: "426898699856",
  appId: "1:426898699856:web:1b094dc7a1eda648be4355",
  measurementId: "G-MFVFHL9G2S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function App({ signOut, user }: any) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const shops = useAppSelector(selectShops);
  const currentShopId = useAppSelector(selectCurrentShopId);
  const showSpinner = useAppSelector(selectShowSpinner);
  const groups = useAppSelector(selectUserGroups);

  const userWithType: CognitoUserAmplify = user;

  useEffect(() => {
    dispatch(fetchShops() as any);
  }, []);

  useEffect(() => {
    if (!currentShopId && shops.length > 0) {
      let shopId = localStorage.getItem("currentShopId");

      if (shopId && shops.find((shop) => shop.id === shopId)) {
        dispatch(setCurrentShopId(shopId));
      } else {
        dispatch(setCurrentShopId(shops[0].id));
      }
    }
  }, [shops]);

  useEffect(() => {
    fetchCognitoUser(user);
  }, [user]);

  async function fetchCognitoUser(user: any) {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));
  }

  async function refreshUser() {
    toast.info("사용자 권한을 새로고침합니다.");

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    const param = groups ?? [];

    dispatch(setUserGroups(param));
  }

  if (!userWithType.attributes?.email_verified) {
    return (
      <Container>
        <Row>
          <Col className="text-center" style={{ paddingTop: 20 }}>
            <Alert>이메일 인증 후 사용하실 수 있습니다.</Alert>
            <div>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  refreshUser();
                }}
              >
                새로고침
              </Button>{" "}
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  signOut();
                }}
              >
                로그아웃
              </Button>
            </div>
            <ToastContainer position="top-center" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (!groups.includes("Admin")) {
    return (
      <Container>
        <Row>
          <Col className="text-center" style={{ paddingTop: 20 }}>
            <Alert>IT 관리자 권한 부여 후 사용하실 수 있습니다.</Alert>
            <div>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  refreshUser();
                }}
              >
                권한 새로고침
              </Button>{" "}
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  signOut();
                }}
              >
                로그아웃
              </Button>
            </div>
            <ToastContainer position="top-center" />
          </Col>
        </Row>
      </Container>
    );
  }

  const isProduction = process.env.REACT_APP_STAGE === "prod";

  return (
    <div>
      {!isProduction && (
        <div
          style={{
            textAlign: "center",
            marginBottom: 5,
            background: "#e5dfd9",
            color: "#2b2b2b",
          }}
        >
          테스트 사이트
        </div>
      )}
      <Navigation />
      {showSpinner && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Container>
        <Outlet />
      </Container>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default withAuthenticator(App, {
  components: {
    Header: () => (
      <>
        <div style={{ marginBottom: 30 }}>
          <img
            src="/images/icons/nanalog-app-logo-140.png"
            alt="나나로그 로고"
            width={140}
            height={140}
          />
          <h5 style={{ marginTop: 10 }}>퓨처뷰티 본사 시스템</h5>
        </div>
      </>
    ),
    Footer: () => (
      <>
        <Footer />
      </>
    ),
  },
});

export { db };
