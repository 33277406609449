import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import numeral from "numeral";
import {
  Booking,
  BookingByShopIdQueryVariables,
  Shop,
} from "../../graphql/API";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { fetchShops, selectShops } from "../../features/shop";
import { useAppSelector } from "../../app/hooks";
import { setShowShopModal } from "../../features/common";
import ShopModal from "./Modal";
import dayjs from "dayjs";
import { bookingByShopId } from "../../graphql/queries";
import { API } from "aws-amplify";
import DesignerAPI from "../../api/designer";
import BookingAPI from "../../api/booking";

export default function ShopList() {
  const dispatch = useDispatch();

  const shops = useAppSelector(selectShops);
  const [shop, setShop] = useState<Shop>();

  useEffect(() => {
    dispatch(fetchShops() as any);
  }, []);

  const [inProgress, setInProgress] = useState(false);

  async function fillInDesignerId(shopId: string) {
    if (inProgress) {
      return;
    }

    try {
      setInProgress(true);

      const designerAPI = new DesignerAPI();
      const bookingAPI = new BookingAPI();

      const designers = await designerAPI.listDesigners(shopId);

      const variables: BookingByShopIdQueryVariables = {
        shopId,
        date: {
          gt: dayjs().format("YYYY-MM-DD"),
        },
        filter: {
          designerId: {
            attributeExists: false,
          },
        },
      };

      const response: any = await API.graphql({
        query: bookingByShopId,
        variables,
      });

      let bookings = response.data.bookingByShopId.items;

      bookings = bookings.filter((booking: Booking) => !booking.designerId);

      bookings.forEach((booking: Booking) => {
        const designerId = designers.find(
          (item) =>
            item.name === booking.designerName ||
            booking.designerName?.includes(item.name)
        )?.id;

        if (designerId) {
          bookingAPI.updateBooking({
            id: booking.id,
            _version: booking._version,
            designerId,
          });
        }
      });
    } catch (error) {
    } finally {
      setInProgress(false);
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>매장</h4>
            <Table responsive>
              <colgroup>
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>매장 이름</th>
                  <th>네이버 고유번호</th>
                  <th>락커 보유 여부</th>
                  <th>사전 진단 발송 여부</th>
                  <th>시술 후 피드백 요청 발송 여부</th>
                  <th>QR</th>
                  <th>등록일시</th>
                  <th>변경일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {shops?.map((shop) => {
                  return (
                    <tr key={shop.id}>
                      <td>{shop.name}</td>
                      <td>{shop.naverBusinessId}</td>
                      <td>{shop.hasLocker ? "Y" : ""}</td>
                      <td>{shop.usePreSelfDiagnosis ? "Y" : ""}</td>
                      <td>{shop.useFeedbackRequest ? "Y" : ""}</td>
                      <td>{shop.bookingQRS3Key}</td>
                      <td>
                        {dayjs(shop.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        {dayjs(shop.updatedAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            setShop(shop);
                            dispatch(setShowShopModal(true));
                          }}
                        >
                          상세
                        </Button>{" "}
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          className="d-none"
                          onClick={() => {
                            fillInDesignerId(shop.id);
                          }}
                        >
                          데이터 정제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      {shop && <ShopModal shop={shop} />}
    </div>
  );
}
