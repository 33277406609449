import { API } from "aws-amplify";
import { toast } from "react-toastify";
import {
  CreateShopServiceDrinkInput,
  ShopServiceDrink,
  UpdateShopServiceDrinkInput,
  DeleteShopServiceDrinkInput,
} from "../../graphql/API";
import {
  createShopServiceDrink,
  deleteShopServiceDrink,
  updateShopServiceDrink,
} from "../../graphql/mutations";
import { shopServiceDrinkByShopId } from "../../graphql/queries";

export default class ShopServiceDrinkAPI {
  async listByShopId(shopId: string): Promise<ShopServiceDrink[]> {
    const response: any = await API.graphql({
      query: shopServiceDrinkByShopId,
      variables: { shopId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.shopServiceDrinkByShopId.items
      .filter((item: ShopServiceDrink) => !item._deleted)
      .sort(
        (a: ShopServiceDrink, b: ShopServiceDrink) =>
          a.displayOrder - b.displayOrder
      );
  }

  async create(input: CreateShopServiceDrinkInput) {
    return API.graphql({
      query: createShopServiceDrink,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async update(input: UpdateShopServiceDrinkInput) {
    return API.graphql({
      query: updateShopServiceDrink,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async delete(input: DeleteShopServiceDrinkInput) {
    return API.graphql({
      query: deleteShopServiceDrink,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
