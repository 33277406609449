import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import RegularCustomerNoteAPI from "../../api/regular-customer-note";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { RegularCustomerNote } from "../../graphql/API";
import styles from "./index.module.scss";

export default function RegularCustomerNoteList() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [regularCustomerNotes, setRegularCustomerNotes] =
    useState<RegularCustomerNote[]>();

  const regularCustomerNoteAPI = new RegularCustomerNoteAPI();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    initialize(currentShopId);
  }, [currentShopId]);

  async function initialize(shopId: string) {
    const data = await regularCustomerNoteAPI.list(shopId);

    setRegularCustomerNotes(data);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>재방문 고객 사전 진단 기록</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th>고객</th>
                  <th>스타일 변화 정도</th>
                  <th>서비스 이후 일정</th>
                  <th>텍스트</th>
                  <th>작성일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {regularCustomerNotes?.map((regularCustomerNote) => {
                  return (
                    <tr key={regularCustomerNote.bookingId}>
                      <td>{regularCustomerNote.customerName}</td>
                      <td>{regularCustomerNote.levelOfStyleChange}</td>
                      <td>{regularCustomerNote.nextSchedule}</td>
                      <td>{regularCustomerNote.comment}</td>
                      <td>
                        {dayjs(regularCustomerNote.createdAt).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
