import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { Consultation } from "../../graphql/API";
import { consultationByShopId } from "../../graphql/queries";
import styles from "./index.module.scss";

export default function ConsultationList() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [consultations, setConsultations] = useState<Consultation[]>();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    initialize(currentShopId);
  }, [currentShopId]);

  async function initialize(shopId: string) {
    const response: any = await API.graphql(
      graphqlOperation(consultationByShopId, {
        shopId,
        sortDirection: "DESC",
      })
    );

    const data = response.data.consultationByShopId.items;
    setConsultations(data);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>컨설테이션 기록</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>디자이너</th>
                  <th>고객</th>
                  <th>성별</th>
                  <th>경로</th>
                  <th>사진</th>
                  <th>얼굴형</th>
                  <th>고민</th>
                  <th>추천-앞머리</th>
                  <th>솔-케어팁</th>
                  <th>솔-주의</th>
                  <th>전송</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {consultations?.map((consultation) => {
                  return (
                    <tr key={consultation.id}>
                      <td>{consultation.date}</td>
                      <td>
                        {consultation.designerName}
                        <br />
                        <small style={{ color: "gray" }}>
                          {consultation.shopName}
                        </small>
                      </td>
                      <td>{consultation.customer?.name}</td>
                      <td>{consultation.gender}</td>
                      <td>{consultation.inflowChannel}</td>
                      <td>{consultation.hasPhotos ? "Y" : ""}</td>
                      <td>{consultation.faceShapes?.join(", ")}</td>
                      <td>
                        {consultation.concerns?.map((concern) => (
                          <Badge
                            key={concern}
                            bg="info"
                            style={{ marginRight: 5 }}
                          >
                            {concern}
                          </Badge>
                        ))}
                      </td>
                      <td>{consultation.hairRecommendation?.bangs}</td>
                      <td>{consultation.solution?.aftercareTips}</td>
                      <td>{consultation.solution?.precautions}</td>
                      <td>
                        {consultation.solutionSentAt ? (
                          <Badge bg="success">솔루션 전송됨</Badge>
                        ) : (
                          <Badge bg="info">솔루션 미전송</Badge>
                        )}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={() => {
                            const hostName =
                              process.env.REACT_APP_STAGE === "prod"
                                ? "app.nanalogapp.co"
                                : "dev-app.nanalogapp.io";

                            const url = `https://${hostName}/solution/${consultation?.id}`;
                            window.open(url);
                          }}
                        >
                          결과지
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
