import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Nav, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCurrentShopId,
  setCurrentShopId,
} from "../../../features/common";
import { selectShops } from "../../../features/shop";

export default function Navigation() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const shops = useAppSelector(selectShops);
  const currentShopId = useAppSelector(selectCurrentShopId);

  const [activeKey, setActiveKey] = useState<string>("/home");

  function onCurrentShopIdChange(shopId: string) {
    dispatch(setCurrentShopId(shopId));

    localStorage.setItem("currentShopId", shopId);
  }

  function onSelectKeyChange(key: string) {
    if (key === "logout") {
      Auth.signOut();
      return;
    }

    navigate(key);
    setActiveKey(key);
  }

  return (
    <Container style={{ marginTop: 10, marginBottom: 10 }}>
      <Row>
        <Col>
          <Nav
            variant="tabs"
            activeKey={activeKey}
            onSelect={(selectedKey) => onSelectKeyChange(selectedKey ?? "/")}
          >
            <Nav.Item>
              <Nav.Link eventKey="/self-diagnosis-request">
                앱 - 셀프 진단지 발송
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/caring">앱 - 케어링 발송</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/event">앱 - 이벤트</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/user">사용자</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/shop">매장</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/consultation-solution-preset">
                컨설테이션 솔루션 프리셋
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/coupang-product">쿠팡 상품</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/consultation-request">
                <strong>비대면 상담 신청</strong>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="logout">로그아웃</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div style={{ marginTop: 10 }}>
            {shops.map((shop) => (
              <Button
                key={shop.id}
                size="sm"
                variant={
                  currentShopId === shop.id ? "primary" : "outline-primary"
                }
                onClick={() => {
                  onCurrentShopIdChange(shop.id);
                }}
                style={{ marginRight: 5, marginBottom: 5 }}
              >
                {shop.name}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav
            variant="tabs"
            activeKey={activeKey}
            onSelect={(selectedKey) => onSelectKeyChange(selectedKey ?? "/")}
          >
            <Nav.Item>
              <Nav.Link eventKey="/shop-service-drink">서비스 음료</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/designer">디자이너 프로필</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/consultation">컨설테이션</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/regular-customer-note">
                재방문 사전 진단
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/feedback">피드백</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/booking">예약</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/customer">고객</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="/sales-status">매출</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}
