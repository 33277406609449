import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../../App";
import { Container, Row, Col, Table } from "react-bootstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export default function ConsultationRequest() {
  const collectionName = "consultation_requests";

  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const q = query(
      collection(db, collectionName),
      orderBy("createdAt", "desc"),
      limit(100)
    );
    const querySnapshot = await getDocs(q);

    let temp: any[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();

      temp.push({
        id: doc.id,
        ...data,
      });
    });

    setList(temp);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>비대면 상담 신청</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>성별</th>
                  <th>연령</th>
                  <th>직업</th>
                  <th>얼굴형</th>
                  <th>이마선코너</th>
                  <th>길어보이는곳</th>
                  <th>볼륨희망</th>
                  <th>등록일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link to={"/consultation-request-details/" + item.id}>
                          {item.email}
                        </Link>
                      </td>
                      <td>{item.gender}</td>
                      <td>{item.age}</td>
                      <td>{item.job}</td>
                      <td>{item.faceShape}</td>
                      <td>{item.hairLineCorner}</td>
                      <td>{item.longFacePart}</td>
                      <td>{item.volumeNeeds}</td>
                      <td>
                        {item.createdAt
                          ? dayjs(item.createdAt.toDate()).format(
                              "YYYY-MM-DD HH:mm"
                            )
                          : ""}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
