/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const payBooking = /* GraphQL */ `
  mutation PayBooking($id: ID!, $input: PayBookingInput!) {
    payBooking(id: $id, input: $input) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const invokeNaverOpenAPI = /* GraphQL */ `
  mutation InvokeNaverOpenAPI($input: InvokeNaverOpenAPIInput!) {
    invokeNaverOpenAPI(input: $input) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDiscountPreset = /* GraphQL */ `
  mutation CreateDiscountPreset(
    $input: CreateDiscountPresetInput!
    $condition: ModelDiscountPresetConditionInput
  ) {
    createDiscountPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      amount
      rate
      type
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDiscountPreset = /* GraphQL */ `
  mutation UpdateDiscountPreset(
    $input: UpdateDiscountPresetInput!
    $condition: ModelDiscountPresetConditionInput
  ) {
    updateDiscountPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      amount
      rate
      type
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDiscountPreset = /* GraphQL */ `
  mutation DeleteDiscountPreset(
    $input: DeleteDiscountPresetInput!
    $condition: ModelDiscountPresetConditionInput
  ) {
    deleteDiscountPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      amount
      rate
      type
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDesigner = /* GraphQL */ `
  mutation CreateDesigner(
    $input: CreateDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    createDesigner(input: $input, condition: $condition) {
      id
      shopId
      name
      naverName
      businessId
      bizItemId
      phoneNumber
      imageUrl
      s3Key
      userId
      youtubeUrl
      instagramUrl
      inactive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDesigner = /* GraphQL */ `
  mutation UpdateDesigner(
    $input: UpdateDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    updateDesigner(input: $input, condition: $condition) {
      id
      shopId
      name
      naverName
      businessId
      bizItemId
      phoneNumber
      imageUrl
      s3Key
      userId
      youtubeUrl
      instagramUrl
      inactive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDesigner = /* GraphQL */ `
  mutation DeleteDesigner(
    $input: DeleteDesignerInput!
    $condition: ModelDesignerConditionInput
  ) {
    deleteDesigner(input: $input, condition: $condition) {
      id
      shopId
      name
      naverName
      businessId
      bizItemId
      phoneNumber
      imageUrl
      s3Key
      userId
      youtubeUrl
      instagramUrl
      inactive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      bookingId
      category
      shopId
      shopName
      designerName
      designerId
      date
      satisfaction
      comment
      supportMessage
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      bookingId
      category
      shopId
      shopName
      designerName
      designerId
      date
      satisfaction
      comment
      supportMessage
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      bookingId
      category
      shopId
      shopName
      designerName
      designerId
      date
      satisfaction
      comment
      supportMessage
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRegularCustomerNote = /* GraphQL */ `
  mutation CreateRegularCustomerNote(
    $input: CreateRegularCustomerNoteInput!
    $condition: ModelRegularCustomerNoteConditionInput
  ) {
    createRegularCustomerNote(input: $input, condition: $condition) {
      bookingId
      shopId
      shopName
      customerId
      customerName
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRegularCustomerNote = /* GraphQL */ `
  mutation UpdateRegularCustomerNote(
    $input: UpdateRegularCustomerNoteInput!
    $condition: ModelRegularCustomerNoteConditionInput
  ) {
    updateRegularCustomerNote(input: $input, condition: $condition) {
      bookingId
      shopId
      shopName
      customerId
      customerName
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRegularCustomerNote = /* GraphQL */ `
  mutation DeleteRegularCustomerNote(
    $input: DeleteRegularCustomerNoteInput!
    $condition: ModelRegularCustomerNoteConditionInput
  ) {
    deleteRegularCustomerNote(input: $input, condition: $condition) {
      bookingId
      shopId
      shopName
      customerId
      customerName
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserPushTokenRequest = /* GraphQL */ `
  mutation CreateUserPushTokenRequest(
    $input: CreateUserPushTokenRequestInput!
    $condition: ModelUserPushTokenRequestConditionInput
  ) {
    createUserPushTokenRequest(input: $input, condition: $condition) {
      id
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserPushTokenRequest = /* GraphQL */ `
  mutation UpdateUserPushTokenRequest(
    $input: UpdateUserPushTokenRequestInput!
    $condition: ModelUserPushTokenRequestConditionInput
  ) {
    updateUserPushTokenRequest(input: $input, condition: $condition) {
      id
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserPushTokenRequest = /* GraphQL */ `
  mutation DeleteUserPushTokenRequest(
    $input: DeleteUserPushTokenRequestInput!
    $condition: ModelUserPushTokenRequestConditionInput
  ) {
    deleteUserPushTokenRequest(input: $input, condition: $condition) {
      id
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserPushToken = /* GraphQL */ `
  mutation CreateUserPushToken(
    $input: CreateUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    createUserPushToken(input: $input, condition: $condition) {
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserPushToken = /* GraphQL */ `
  mutation UpdateUserPushToken(
    $input: UpdateUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    updateUserPushToken(input: $input, condition: $condition) {
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserPushToken = /* GraphQL */ `
  mutation DeleteUserPushToken(
    $input: DeleteUserPushTokenInput!
    $condition: ModelUserPushTokenConditionInput
  ) {
    deleteUserPushToken(input: $input, condition: $condition) {
      token
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCaring = /* GraphQL */ `
  mutation CreateCaring(
    $input: CreateCaringInput!
    $condition: ModelCaringConditionInput
  ) {
    createCaring(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      timestamp
      pointOfToday
      aftercareTips
      stylingTips
      precautions
      serviceRecommendation
      nextVisitDate
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCaring = /* GraphQL */ `
  mutation UpdateCaring(
    $input: UpdateCaringInput!
    $condition: ModelCaringConditionInput
  ) {
    updateCaring(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      timestamp
      pointOfToday
      aftercareTips
      stylingTips
      precautions
      serviceRecommendation
      nextVisitDate
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCaring = /* GraphQL */ `
  mutation DeleteCaring(
    $input: DeleteCaringInput!
    $condition: ModelCaringConditionInput
  ) {
    deleteCaring(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      timestamp
      pointOfToday
      aftercareTips
      stylingTips
      precautions
      serviceRecommendation
      nextVisitDate
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSelfDiagnosisRequest = /* GraphQL */ `
  mutation CreateSelfDiagnosisRequest(
    $input: CreateSelfDiagnosisRequestInput!
    $condition: ModelSelfDiagnosisRequestConditionInput
  ) {
    createSelfDiagnosisRequest(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSelfDiagnosisRequest = /* GraphQL */ `
  mutation UpdateSelfDiagnosisRequest(
    $input: UpdateSelfDiagnosisRequestInput!
    $condition: ModelSelfDiagnosisRequestConditionInput
  ) {
    updateSelfDiagnosisRequest(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSelfDiagnosisRequest = /* GraphQL */ `
  mutation DeleteSelfDiagnosisRequest(
    $input: DeleteSelfDiagnosisRequestInput!
    $condition: ModelSelfDiagnosisRequestConditionInput
  ) {
    deleteSelfDiagnosisRequest(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSelfDiagnosisContent = /* GraphQL */ `
  mutation CreateSelfDiagnosisContent(
    $input: CreateSelfDiagnosisContentInput!
    $condition: ModelSelfDiagnosisContentConditionInput
  ) {
    createSelfDiagnosisContent(input: $input, condition: $condition) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSelfDiagnosisContent = /* GraphQL */ `
  mutation UpdateSelfDiagnosisContent(
    $input: UpdateSelfDiagnosisContentInput!
    $condition: ModelSelfDiagnosisContentConditionInput
  ) {
    updateSelfDiagnosisContent(input: $input, condition: $condition) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSelfDiagnosisContent = /* GraphQL */ `
  mutation DeleteSelfDiagnosisContent(
    $input: DeleteSelfDiagnosisContentInput!
    $condition: ModelSelfDiagnosisContentConditionInput
  ) {
    deleteSelfDiagnosisContent(input: $input, condition: $condition) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRegularCustomerQuestionnaireRequest = /* GraphQL */ `
  mutation CreateRegularCustomerQuestionnaireRequest(
    $input: CreateRegularCustomerQuestionnaireRequestInput!
    $condition: ModelRegularCustomerQuestionnaireRequestConditionInput
  ) {
    createRegularCustomerQuestionnaireRequest(
      input: $input
      condition: $condition
    ) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRegularCustomerQuestionnaireRequest = /* GraphQL */ `
  mutation UpdateRegularCustomerQuestionnaireRequest(
    $input: UpdateRegularCustomerQuestionnaireRequestInput!
    $condition: ModelRegularCustomerQuestionnaireRequestConditionInput
  ) {
    updateRegularCustomerQuestionnaireRequest(
      input: $input
      condition: $condition
    ) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRegularCustomerQuestionnaireRequest = /* GraphQL */ `
  mutation DeleteRegularCustomerQuestionnaireRequest(
    $input: DeleteRegularCustomerQuestionnaireRequestInput!
    $condition: ModelRegularCustomerQuestionnaireRequestConditionInput
  ) {
    deleteRegularCustomerQuestionnaireRequest(
      input: $input
      condition: $condition
    ) {
      id
      owner
      shopName
      designerName
      name
      phoneNumber
      yearMonth
      date
      sentAt
      writtenAt
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRegularCustomerQuestionnaireContent = /* GraphQL */ `
  mutation CreateRegularCustomerQuestionnaireContent(
    $input: CreateRegularCustomerQuestionnaireContentInput!
    $condition: ModelRegularCustomerQuestionnaireContentConditionInput
  ) {
    createRegularCustomerQuestionnaireContent(
      input: $input
      condition: $condition
    ) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      treatmentsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRegularCustomerQuestionnaireContent = /* GraphQL */ `
  mutation UpdateRegularCustomerQuestionnaireContent(
    $input: UpdateRegularCustomerQuestionnaireContentInput!
    $condition: ModelRegularCustomerQuestionnaireContentConditionInput
  ) {
    updateRegularCustomerQuestionnaireContent(
      input: $input
      condition: $condition
    ) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      treatmentsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRegularCustomerQuestionnaireContent = /* GraphQL */ `
  mutation DeleteRegularCustomerQuestionnaireContent(
    $input: DeleteRegularCustomerQuestionnaireContentInput!
    $condition: ModelRegularCustomerQuestionnaireContentConditionInput
  ) {
    deleteRegularCustomerQuestionnaireContent(
      input: $input
      condition: $condition
    ) {
      requestId
      shopName
      designerName
      name
      yearMonth
      date
      levelOfStyleChange
      nextSchedule
      comment
      productsInterestedIn
      treatmentsInterestedIn
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      timestamp
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserActivitySnapshot = /* GraphQL */ `
  mutation CreateUserActivitySnapshot(
    $input: CreateUserActivitySnapshotInput!
    $condition: ModelUserActivitySnapshotConditionInput
  ) {
    createUserActivitySnapshot(input: $input, condition: $condition) {
      id
      owner
      dailyLogCount
      selfDiagnosisCount
      regularCustomerQuestionnaireCount
      caringCount
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserActivitySnapshot = /* GraphQL */ `
  mutation UpdateUserActivitySnapshot(
    $input: UpdateUserActivitySnapshotInput!
    $condition: ModelUserActivitySnapshotConditionInput
  ) {
    updateUserActivitySnapshot(input: $input, condition: $condition) {
      id
      owner
      dailyLogCount
      selfDiagnosisCount
      regularCustomerQuestionnaireCount
      caringCount
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserActivitySnapshot = /* GraphQL */ `
  mutation DeleteUserActivitySnapshot(
    $input: DeleteUserActivitySnapshotInput!
    $condition: ModelUserActivitySnapshotConditionInput
  ) {
    deleteUserActivitySnapshot(input: $input, condition: $condition) {
      id
      owner
      dailyLogCount
      selfDiagnosisCount
      regularCustomerQuestionnaireCount
      caringCount
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDailyLog = /* GraphQL */ `
  mutation CreateDailyLog(
    $input: CreateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    createDailyLog(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      yearMonth
      date
      timestamp
      averageConsultationMinutes
      serviceRecommendationCount
      productRecommendationCount
      prepaidTicketRecommendationCount
      stretch
      drinkingWater
      comment
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDailyLog = /* GraphQL */ `
  mutation UpdateDailyLog(
    $input: UpdateDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    updateDailyLog(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      yearMonth
      date
      timestamp
      averageConsultationMinutes
      serviceRecommendationCount
      productRecommendationCount
      prepaidTicketRecommendationCount
      stretch
      drinkingWater
      comment
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDailyLog = /* GraphQL */ `
  mutation DeleteDailyLog(
    $input: DeleteDailyLogInput!
    $condition: ModelDailyLogConditionInput
  ) {
    deleteDailyLog(input: $input, condition: $condition) {
      id
      owner
      shopName
      designerName
      yearMonth
      date
      timestamp
      averageConsultationMinutes
      serviceRecommendationCount
      productRecommendationCount
      prepaidTicketRecommendationCount
      stretch
      drinkingWater
      comment
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSelfDiagnosis = /* GraphQL */ `
  mutation CreateSelfDiagnosis(
    $input: CreateSelfDiagnosisInput!
    $condition: ModelSelfDiagnosisConditionInput
  ) {
    createSelfDiagnosis(input: $input, condition: $condition) {
      bookingId
      customerId
      customerName
      yearMonth
      date
      shopId
      shopName
      designerId
      designerName
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSelfDiagnosis = /* GraphQL */ `
  mutation UpdateSelfDiagnosis(
    $input: UpdateSelfDiagnosisInput!
    $condition: ModelSelfDiagnosisConditionInput
  ) {
    updateSelfDiagnosis(input: $input, condition: $condition) {
      bookingId
      customerId
      customerName
      yearMonth
      date
      shopId
      shopName
      designerId
      designerName
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSelfDiagnosis = /* GraphQL */ `
  mutation DeleteSelfDiagnosis(
    $input: DeleteSelfDiagnosisInput!
    $condition: ModelSelfDiagnosisConditionInput
  ) {
    deleteSelfDiagnosis(input: $input, condition: $condition) {
      bookingId
      customerId
      customerName
      yearMonth
      date
      shopId
      shopName
      designerId
      designerName
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      recentDyeing
      recentPerm
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      blackDyedHair
      bleachedHair
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConsultation = /* GraphQL */ `
  mutation CreateConsultation(
    $input: CreateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    createConsultation(input: $input, condition: $condition) {
      id
      bookingId
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      yearMonth
      date
      shopId
      status
      selfDiagnosisStatus
      mode
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      neckLength
      neckThickness
      shoulderWidth
      shoulderShape
      hairColor
      virginHairColor
      colorfulnessOfHair
      colorfulnessesOfHair
      recentDyeing
      recentFirm
      damageDegreeOfHair
      amountOfHair
      thicknessOfHair
      curlDegreeOfHair
      faceShape
      faceShapes
      frontHeadShape
      sideHeadShape
      solution {
        face
        hair
        needs
        point
        aftercareTips
        stylingTips
        precautions
        recommendedTreatment
        nextVisitDate
      }
      solutionSentAt
      hairRecommendation {
        bangs
        hairLength
        hairVolume
        hairVolumes
        haircut
        haircuts
        hairColorMenu
        hairColorType
        hairFirm
        hairCare
      }
      products {
        title
        url
        imageFilePath
        description
      }
      shopName
      designerName
      designerId
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConsultation = /* GraphQL */ `
  mutation UpdateConsultation(
    $input: UpdateConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    updateConsultation(input: $input, condition: $condition) {
      id
      bookingId
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      yearMonth
      date
      shopId
      status
      selfDiagnosisStatus
      mode
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      neckLength
      neckThickness
      shoulderWidth
      shoulderShape
      hairColor
      virginHairColor
      colorfulnessOfHair
      colorfulnessesOfHair
      recentDyeing
      recentFirm
      damageDegreeOfHair
      amountOfHair
      thicknessOfHair
      curlDegreeOfHair
      faceShape
      faceShapes
      frontHeadShape
      sideHeadShape
      solution {
        face
        hair
        needs
        point
        aftercareTips
        stylingTips
        precautions
        recommendedTreatment
        nextVisitDate
      }
      solutionSentAt
      hairRecommendation {
        bangs
        hairLength
        hairVolume
        hairVolumes
        haircut
        haircuts
        hairColorMenu
        hairColorType
        hairFirm
        hairCare
      }
      products {
        title
        url
        imageFilePath
        description
      }
      shopName
      designerName
      designerId
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConsultation = /* GraphQL */ `
  mutation DeleteConsultation(
    $input: DeleteConsultationInput!
    $condition: ModelConsultationConditionInput
  ) {
    deleteConsultation(input: $input, condition: $condition) {
      id
      bookingId
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      yearMonth
      date
      shopId
      status
      selfDiagnosisStatus
      mode
      inflowChannel
      gender
      dry
      concerns
      frequencyOfVisits
      outfitStyles
      treatmentsInterestedIn
      productsForShampooing
      productsForStyling
      neckLength
      neckThickness
      shoulderWidth
      shoulderShape
      hairColor
      virginHairColor
      colorfulnessOfHair
      colorfulnessesOfHair
      recentDyeing
      recentFirm
      damageDegreeOfHair
      amountOfHair
      thicknessOfHair
      curlDegreeOfHair
      faceShape
      faceShapes
      frontHeadShape
      sideHeadShape
      solution {
        face
        hair
        needs
        point
        aftercareTips
        stylingTips
        precautions
        recommendedTreatment
        nextVisitDate
      }
      solutionSentAt
      hairRecommendation {
        bangs
        hairLength
        hairVolume
        hairVolumes
        haircut
        haircuts
        hairColorMenu
        hairColorType
        hairFirm
        hairCare
      }
      products {
        title
        url
        imageFilePath
        description
      }
      shopName
      designerName
      designerId
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAnonymousPhoto = /* GraphQL */ `
  mutation CreateAnonymousPhoto(
    $input: CreateAnonymousPhotoInput!
    $condition: ModelAnonymousPhotoConditionInput
  ) {
    createAnonymousPhoto(input: $input, condition: $condition) {
      id
      bookingId
      consultationId
      requestId
      s3Key
      displayOrder
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAnonymousPhoto = /* GraphQL */ `
  mutation UpdateAnonymousPhoto(
    $input: UpdateAnonymousPhotoInput!
    $condition: ModelAnonymousPhotoConditionInput
  ) {
    updateAnonymousPhoto(input: $input, condition: $condition) {
      id
      bookingId
      consultationId
      requestId
      s3Key
      displayOrder
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAnonymousPhoto = /* GraphQL */ `
  mutation DeleteAnonymousPhoto(
    $input: DeleteAnonymousPhotoInput!
    $condition: ModelAnonymousPhotoConditionInput
  ) {
    deleteAnonymousPhoto(input: $input, condition: $condition) {
      id
      bookingId
      consultationId
      requestId
      s3Key
      displayOrder
      category
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      bookingId
      s3Key
      displayOrder
      identityId
      description
      category
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      bookingId
      s3Key
      displayOrder
      identityId
      description
      category
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      bookingId
      s3Key
      displayOrder
      identityId
      description
      category
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCheckIn = /* GraphQL */ `
  mutation CreateCheckIn(
    $input: CreateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    createCheckIn(input: $input, condition: $condition) {
      bookingId
      shopId
      checkInRequests
      drink
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCheckIn = /* GraphQL */ `
  mutation UpdateCheckIn(
    $input: UpdateCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    updateCheckIn(input: $input, condition: $condition) {
      bookingId
      shopId
      checkInRequests
      drink
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCheckIn = /* GraphQL */ `
  mutation DeleteCheckIn(
    $input: DeleteCheckInInput!
    $condition: ModelCheckInConditionInput
  ) {
    deleteCheckIn(input: $input, condition: $condition) {
      bookingId
      shopId
      checkInRequests
      drink
      modelVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      yearMonth
      date
      time
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      phoneNumber
      consultationId
      consultation {
        status
        selfDiagnosisStatus
        solutionSentAt
        inflowChannel
        hasPhotos
      }
      hasSelfDiagnosis
      hasRegularCustomerNote
      designerName
      designerId
      optionName
      shopId
      shopName
      naverBookingNumber
      bookingStatus
      serviceStatus
      paymentStatus
      requestMessage
      designerMemo
      naverSnapshot {
        price
        bizItemPrice
        onsitePrice
        email
        isOnsitePayment
      }
      naverOptions {
        categoryName
        name
        originalName
        desc
        price
      }
      naverPayments {
        method
        moment
        provider
        paymentId
        bookingId
        amount
        providerDiscountAmount
        regDateTime
        status
        statusHistories {
          status
          dateTime
        }
      }
      initialPrice
      finalPrice
      totalServiceSales
      totalProductSales
      initialDeposit
      paidAmount
      paidInCashAmount
      paidByCardAmount
      paidByPrepaidPassAmount
      paidByNaverPayAmount
      paidAt
      paymentMemo
      checkInRequests
      drink
      checkedInAt
      origin
      options {
        categoryName
        name
        originalPrice
        discount
        discountRate
        price
        discountPreset {
          id
          shopId
          name
          amount
          rate
          type
        }
      }
      productOptions {
        name
        price
        quantity
        product {
          id
          name
          shopId
          brandId
          brandName
          costPrice
          sellingPrice
        }
      }
      signature
      signedAt
      recentNaverDataReflection
      selfDiagnosisSentAt
      feedbackRequestSentAt
      hasFeedback
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      hasDesignCompletionPhotos
      designCompletionPhotos {
        s3Key
        displayOrder
      }
      signatureOfPortraitRights
      signedAtOfPortraitRights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      yearMonth
      date
      time
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      phoneNumber
      consultationId
      consultation {
        status
        selfDiagnosisStatus
        solutionSentAt
        inflowChannel
        hasPhotos
      }
      hasSelfDiagnosis
      hasRegularCustomerNote
      designerName
      designerId
      optionName
      shopId
      shopName
      naverBookingNumber
      bookingStatus
      serviceStatus
      paymentStatus
      requestMessage
      designerMemo
      naverSnapshot {
        price
        bizItemPrice
        onsitePrice
        email
        isOnsitePayment
      }
      naverOptions {
        categoryName
        name
        originalName
        desc
        price
      }
      naverPayments {
        method
        moment
        provider
        paymentId
        bookingId
        amount
        providerDiscountAmount
        regDateTime
        status
        statusHistories {
          status
          dateTime
        }
      }
      initialPrice
      finalPrice
      totalServiceSales
      totalProductSales
      initialDeposit
      paidAmount
      paidInCashAmount
      paidByCardAmount
      paidByPrepaidPassAmount
      paidByNaverPayAmount
      paidAt
      paymentMemo
      checkInRequests
      drink
      checkedInAt
      origin
      options {
        categoryName
        name
        originalPrice
        discount
        discountRate
        price
        discountPreset {
          id
          shopId
          name
          amount
          rate
          type
        }
      }
      productOptions {
        name
        price
        quantity
        product {
          id
          name
          shopId
          brandId
          brandName
          costPrice
          sellingPrice
        }
      }
      signature
      signedAt
      recentNaverDataReflection
      selfDiagnosisSentAt
      feedbackRequestSentAt
      hasFeedback
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      hasDesignCompletionPhotos
      designCompletionPhotos {
        s3Key
        displayOrder
      }
      signatureOfPortraitRights
      signedAtOfPortraitRights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      yearMonth
      date
      time
      customer {
        phoneNumber
        name
        id
        shopIds
      }
      customerId
      name
      phoneNumber
      consultationId
      consultation {
        status
        selfDiagnosisStatus
        solutionSentAt
        inflowChannel
        hasPhotos
      }
      hasSelfDiagnosis
      hasRegularCustomerNote
      designerName
      designerId
      optionName
      shopId
      shopName
      naverBookingNumber
      bookingStatus
      serviceStatus
      paymentStatus
      requestMessage
      designerMemo
      naverSnapshot {
        price
        bizItemPrice
        onsitePrice
        email
        isOnsitePayment
      }
      naverOptions {
        categoryName
        name
        originalName
        desc
        price
      }
      naverPayments {
        method
        moment
        provider
        paymentId
        bookingId
        amount
        providerDiscountAmount
        regDateTime
        status
        statusHistories {
          status
          dateTime
        }
      }
      initialPrice
      finalPrice
      totalServiceSales
      totalProductSales
      initialDeposit
      paidAmount
      paidInCashAmount
      paidByCardAmount
      paidByPrepaidPassAmount
      paidByNaverPayAmount
      paidAt
      paymentMemo
      checkInRequests
      drink
      checkedInAt
      origin
      options {
        categoryName
        name
        originalPrice
        discount
        discountRate
        price
        discountPreset {
          id
          shopId
          name
          amount
          rate
          type
        }
      }
      productOptions {
        name
        price
        quantity
        product {
          id
          name
          shopId
          brandId
          brandName
          costPrice
          sellingPrice
        }
      }
      signature
      signedAt
      recentNaverDataReflection
      selfDiagnosisSentAt
      feedbackRequestSentAt
      hasFeedback
      hasPhotos
      photos {
        s3Key
        displayOrder
      }
      hasDesignCompletionPhotos
      designCompletionPhotos {
        s3Key
        displayOrder
      }
      signatureOfPortraitRights
      signedAtOfPortraitRights
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      lastFourPhoneNumbers
      firstVisitDate
      occupation
      drink
      dress
      trouble
      memo
      bookingCount
      consultationCount
      consultationDoneCount
      signature
      totalRevenue
      vehicleNumber
      shopId
      shopIds
      shopIdsString
      createdAt
      prepaidPassBalance
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      lastFourPhoneNumbers
      firstVisitDate
      occupation
      drink
      dress
      trouble
      memo
      bookingCount
      consultationCount
      consultationDoneCount
      signature
      totalRevenue
      vehicleNumber
      shopId
      shopIds
      shopIdsString
      createdAt
      prepaidPassBalance
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      lastFourPhoneNumbers
      firstVisitDate
      occupation
      drink
      dress
      trouble
      memo
      bookingCount
      consultationCount
      consultationDoneCount
      signature
      totalRevenue
      vehicleNumber
      shopId
      shopIds
      shopIdsString
      createdAt
      prepaidPassBalance
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      owner
      ownerName
      ownerPhoneNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      owner
      ownerName
      ownerPhoneNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      owner
      ownerName
      ownerPhoneNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createShopServiceDrink = /* GraphQL */ `
  mutation CreateShopServiceDrink(
    $input: CreateShopServiceDrinkInput!
    $condition: ModelShopServiceDrinkConditionInput
  ) {
    createShopServiceDrink(input: $input, condition: $condition) {
      id
      name
      shopId
      outOfStock
      displayOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateShopServiceDrink = /* GraphQL */ `
  mutation UpdateShopServiceDrink(
    $input: UpdateShopServiceDrinkInput!
    $condition: ModelShopServiceDrinkConditionInput
  ) {
    updateShopServiceDrink(input: $input, condition: $condition) {
      id
      name
      shopId
      outOfStock
      displayOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteShopServiceDrink = /* GraphQL */ `
  mutation DeleteShopServiceDrink(
    $input: DeleteShopServiceDrinkInput!
    $condition: ModelShopServiceDrinkConditionInput
  ) {
    deleteShopServiceDrink(input: $input, condition: $condition) {
      id
      name
      shopId
      outOfStock
      displayOrder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createShopUserApplication = /* GraphQL */ `
  mutation CreateShopUserApplication(
    $input: CreateShopUserApplicationInput!
    $condition: ModelShopUserApplicationConditionInput
  ) {
    createShopUserApplication(input: $input, condition: $condition) {
      id
      shopId
      userId
      phoneNumber
      completed
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateShopUserApplication = /* GraphQL */ `
  mutation UpdateShopUserApplication(
    $input: UpdateShopUserApplicationInput!
    $condition: ModelShopUserApplicationConditionInput
  ) {
    updateShopUserApplication(input: $input, condition: $condition) {
      id
      shopId
      userId
      phoneNumber
      completed
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteShopUserApplication = /* GraphQL */ `
  mutation DeleteShopUserApplication(
    $input: DeleteShopUserApplicationInput!
    $condition: ModelShopUserApplicationConditionInput
  ) {
    deleteShopUserApplication(input: $input, condition: $condition) {
      id
      shopId
      userId
      phoneNumber
      completed
      name
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createShopUserRemovalRequest = /* GraphQL */ `
  mutation CreateShopUserRemovalRequest(
    $input: CreateShopUserRemovalRequestInput!
    $condition: ModelShopUserRemovalRequestConditionInput
  ) {
    createShopUserRemovalRequest(input: $input, condition: $condition) {
      designerId
      shopId
      userId
      completed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateShopUserRemovalRequest = /* GraphQL */ `
  mutation UpdateShopUserRemovalRequest(
    $input: UpdateShopUserRemovalRequestInput!
    $condition: ModelShopUserRemovalRequestConditionInput
  ) {
    updateShopUserRemovalRequest(input: $input, condition: $condition) {
      designerId
      shopId
      userId
      completed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteShopUserRemovalRequest = /* GraphQL */ `
  mutation DeleteShopUserRemovalRequest(
    $input: DeleteShopUserRemovalRequestInput!
    $condition: ModelShopUserRemovalRequestConditionInput
  ) {
    deleteShopUserRemovalRequest(input: $input, condition: $condition) {
      designerId
      shopId
      userId
      completed
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createServiceOption = /* GraphQL */ `
  mutation CreateServiceOption(
    $input: CreateServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    createServiceOption(input: $input, condition: $condition) {
      shopId
      categories {
        name
        options {
          name
          price
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateServiceOption = /* GraphQL */ `
  mutation UpdateServiceOption(
    $input: UpdateServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    updateServiceOption(input: $input, condition: $condition) {
      shopId
      categories {
        name
        options {
          name
          price
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteServiceOption = /* GraphQL */ `
  mutation DeleteServiceOption(
    $input: DeleteServiceOptionInput!
    $condition: ModelServiceOptionConditionInput
  ) {
    deleteServiceOption(input: $input, condition: $condition) {
      shopId
      categories {
        name
        options {
          name
          price
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPrepaidPassPreset = /* GraphQL */ `
  mutation CreatePrepaidPassPreset(
    $input: CreatePrepaidPassPresetInput!
    $condition: ModelPrepaidPassPresetConditionInput
  ) {
    createPrepaidPassPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      price
      availableAmount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePrepaidPassPreset = /* GraphQL */ `
  mutation UpdatePrepaidPassPreset(
    $input: UpdatePrepaidPassPresetInput!
    $condition: ModelPrepaidPassPresetConditionInput
  ) {
    updatePrepaidPassPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      price
      availableAmount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePrepaidPassPreset = /* GraphQL */ `
  mutation DeletePrepaidPassPreset(
    $input: DeletePrepaidPassPresetInput!
    $condition: ModelPrepaidPassPresetConditionInput
  ) {
    deletePrepaidPassPreset(input: $input, condition: $condition) {
      id
      shopId
      name
      price
      availableAmount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPrepaidPassHistory = /* GraphQL */ `
  mutation CreatePrepaidPassHistory(
    $input: CreatePrepaidPassHistoryInput!
    $condition: ModelPrepaidPassHistoryConditionInput
  ) {
    createPrepaidPassHistory(input: $input, condition: $condition) {
      id
      customerId
      shopId
      amount
      bookingId
      type
      description
      referenceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePrepaidPassHistory = /* GraphQL */ `
  mutation UpdatePrepaidPassHistory(
    $input: UpdatePrepaidPassHistoryInput!
    $condition: ModelPrepaidPassHistoryConditionInput
  ) {
    updatePrepaidPassHistory(input: $input, condition: $condition) {
      id
      customerId
      shopId
      amount
      bookingId
      type
      description
      referenceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePrepaidPassHistory = /* GraphQL */ `
  mutation DeletePrepaidPassHistory(
    $input: DeletePrepaidPassHistoryInput!
    $condition: ModelPrepaidPassHistoryConditionInput
  ) {
    deletePrepaidPassHistory(input: $input, condition: $condition) {
      id
      customerId
      shopId
      amount
      bookingId
      type
      description
      referenceId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      shopId
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      shopId
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      shopId
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      shopId
      brandId
      brandName
      costPrice
      sellingPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      shopId
      brandId
      brandName
      costPrice
      sellingPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      shopId
      brandId
      brandName
      costPrice
      sellingPrice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createConsultationSolutionPreset = /* GraphQL */ `
  mutation CreateConsultationSolutionPreset(
    $input: CreateConsultationSolutionPresetInput!
    $condition: ModelConsultationSolutionPresetConditionInput
  ) {
    createConsultationSolutionPreset(input: $input, condition: $condition) {
      id
      category
      sentence
      priority
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateConsultationSolutionPreset = /* GraphQL */ `
  mutation UpdateConsultationSolutionPreset(
    $input: UpdateConsultationSolutionPresetInput!
    $condition: ModelConsultationSolutionPresetConditionInput
  ) {
    updateConsultationSolutionPreset(input: $input, condition: $condition) {
      id
      category
      sentence
      priority
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteConsultationSolutionPreset = /* GraphQL */ `
  mutation DeleteConsultationSolutionPreset(
    $input: DeleteConsultationSolutionPresetInput!
    $condition: ModelConsultationSolutionPresetConditionInput
  ) {
    deleteConsultationSolutionPreset(input: $input, condition: $condition) {
      id
      category
      sentence
      priority
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCoupangProduct = /* GraphQL */ `
  mutation CreateCoupangProduct(
    $input: CreateCoupangProductInput!
    $condition: ModelCoupangProductConditionInput
  ) {
    createCoupangProduct(input: $input, condition: $condition) {
      id
      src
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCoupangProduct = /* GraphQL */ `
  mutation UpdateCoupangProduct(
    $input: UpdateCoupangProductInput!
    $condition: ModelCoupangProductConditionInput
  ) {
    updateCoupangProduct(input: $input, condition: $condition) {
      id
      src
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCoupangProduct = /* GraphQL */ `
  mutation DeleteCoupangProduct(
    $input: DeleteCoupangProductInput!
    $condition: ModelCoupangProductConditionInput
  ) {
    deleteCoupangProduct(input: $input, condition: $condition) {
      id
      src
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGeneralProduct = /* GraphQL */ `
  mutation CreateGeneralProduct(
    $input: CreateGeneralProductInput!
    $condition: ModelGeneralProductConditionInput
  ) {
    createGeneralProduct(input: $input, condition: $condition) {
      id
      link
      name
      brandName
      description
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGeneralProduct = /* GraphQL */ `
  mutation UpdateGeneralProduct(
    $input: UpdateGeneralProductInput!
    $condition: ModelGeneralProductConditionInput
  ) {
    updateGeneralProduct(input: $input, condition: $condition) {
      id
      link
      name
      brandName
      description
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGeneralProduct = /* GraphQL */ `
  mutation DeleteGeneralProduct(
    $input: DeleteGeneralProductInput!
    $condition: ModelGeneralProductConditionInput
  ) {
    deleteGeneralProduct(input: $input, condition: $condition) {
      id
      link
      name
      brandName
      description
      imageUrl
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      hostName
      speakerName
      name
      description
      city
      venue
      link
      yearMonth
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      hostName
      speakerName
      name
      description
      city
      venue
      link
      yearMonth
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      hostName
      speakerName
      name
      description
      city
      venue
      link
      yearMonth
      startDate
      endDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMutationResponse = /* GraphQL */ `
  mutation CreateMutationResponse(
    $input: CreateMutationResponseInput!
    $condition: ModelMutationResponseConditionInput
  ) {
    createMutationResponse(input: $input, condition: $condition) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMutationResponse = /* GraphQL */ `
  mutation UpdateMutationResponse(
    $input: UpdateMutationResponseInput!
    $condition: ModelMutationResponseConditionInput
  ) {
    updateMutationResponse(input: $input, condition: $condition) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMutationResponse = /* GraphQL */ `
  mutation DeleteMutationResponse(
    $input: DeleteMutationResponseInput!
    $condition: ModelMutationResponseConditionInput
  ) {
    deleteMutationResponse(input: $input, condition: $condition) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const approveShopUserApplication = /* GraphQL */ `
  mutation ApproveShopUserApplication($id: ID!) {
    approveShopUserApplication(id: $id) {
      success
      reason
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
