import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import APIGateway from "../../api/api-gateway";
import { CoupangProduct, CreateCoupangProductInput } from "../../graphql/API";

export default function CoupangProductList() {
  const apiGateway = new APIGateway();

  const [list, setList] = useState<CoupangProduct[]>([]);

  const defaultInput = (): CreateCoupangProductInput => {
    return {
      src: "",
    };
  };

  const [input, setInput] = useState<CreateCoupangProductInput>(defaultInput());

  useEffect(() => {
    fetchCoupangProducts();
  }, []);

  async function fetchCoupangProducts() {
    const response = await apiGateway.coupangProduct.list();

    setList(response);
  }

  async function create() {
    const { src } = input;

    if (!src) {
      toast.warn("URL을 입력해 주세요.");
      return;
    }

    await apiGateway.coupangProduct.create(input);

    toast.success("생성되었습니다.");

    setInput(defaultInput());

    fetchCoupangProducts();
  }

  async function deleteItem(item: CoupangProduct) {
    if (!confirm("삭제하시겠습니까?\n" + item.src)) {
      return;
    }
    await apiGateway.coupangProduct.delete({
      id: item.id,
      _version: item._version,
    });

    fetchCoupangProducts();
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>쿠팡 상품 관리</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <colgroup>
                <col width="30%" />
                <col width="50%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>상품 URL</th>
                  <th>설명</th>
                  <th>등록일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.src}</td>
                      <td>{item.description}</td>
                      <td>
                        {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            deleteItem(item);
                          }}
                          size="sm"
                          variant="outline-warning"
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              placeholder="URL"
              value={input?.src}
              onChange={(event) => {
                setInput({
                  ...input,
                  src: event.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="설명"
              value={input?.description ?? ""}
              onChange={(event) => {
                setInput({
                  ...input,
                  description: event.target.value,
                });
              }}
            />
          </Col>
          <Col xs="auto">
            <Button onClick={create}>등록</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
