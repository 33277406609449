import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import APIGateway from "../../api/api-gateway";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import {
  ShopServiceDrink,
  CreateShopServiceDrinkInput,
} from "../../graphql/API";

export default function ShopServiceDrinkList() {
  const apiGateway = new APIGateway();

  const currentShopId = useAppSelector(selectCurrentShopId);

  const [list, setList] = useState<ShopServiceDrink[]>([]);

  const defaultInput = (): CreateShopServiceDrinkInput => {
    return {
      name: "",
      shopId: "",
      displayOrder: 0,
    };
  };

  const [input, setInput] = useState<CreateShopServiceDrinkInput>(
    defaultInput()
  );

  useEffect(() => {
    fetchShopServiceDrinks();
  }, [currentShopId]);

  async function fetchShopServiceDrinks() {
    if (!currentShopId) return;

    const response = await apiGateway.shopServiceDrink.listByShopId(
      currentShopId
    );

    setList(response);
  }

  async function create() {
    if (!currentShopId) {
      return;
    }

    const { name } = input;

    if (!name) {
      toast.warn("URL을 입력해 주세요.");
      return;
    }

    input.shopId = currentShopId;

    await apiGateway.shopServiceDrink.create(input);

    toast.success("생성되었습니다.");

    setInput(defaultInput());

    fetchShopServiceDrinks();
  }

  async function deleteItem(item: ShopServiceDrink) {
    if (!confirm("삭제하시겠습니까?\n" + item.name)) {
      return;
    }
    await apiGateway.shopServiceDrink.delete({
      id: item.id,
      _version: item._version,
    });

    fetchShopServiceDrinks();
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>서비스 음료 관리</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <colgroup>
                <col width="30%" />
                <col width="50%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>음료</th>
                  <th>순서</th>
                  <th>등록일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.displayOrder}</td>
                      <td>
                        {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            deleteItem(item);
                          }}
                          size="sm"
                          variant="outline-warning"
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              placeholder="음료"
              value={input?.name}
              onChange={(event) => {
                setInput({
                  ...input,
                  name: event.target.value,
                });
              }}
            />
          </Col>
          <Col xs="auto">
            <Button onClick={create}>등록</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
