import { API, graphqlOperation } from "aws-amplify";
import { UpdateBookingInput } from "../../graphql/API";

const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
    }
  }
`;

export default class BookingAPI {
  async updateBooking(input: UpdateBookingInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }

    return API.graphql(
      graphqlOperation(updateBooking, {
        input,
      })
    );
  }
}
