import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { RootState } from "../../app/store";
import { Shop } from "../../graphql/API";

const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        naverBusinessId
        hasLocker
        bookingQRS3Key
        usePreSelfDiagnosis
        useFeedbackRequest
        internPhoneNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export interface ShopState {
  shops: Shop[];
}

const initialState: ShopState = {
  shops: [],
};

const fetchShops = createAsyncThunk("shops/fetchShops", async () => {
  const response: any = await API.graphql({
    query: listShops,
  });
  return response.data.listShops.items;
});

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchShops.fulfilled,
      (state, { payload }: { payload: Shop[] }) => {
        state.shops = payload
          .filter((item) => !item._deleted)
          .sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
      }
    );
  },
});

export const selectShops = (state: RootState) => state.shop.shops;

export { fetchShops };

export default shopSlice.reducer;
