import { API } from "aws-amplify";
import { toast } from "react-toastify";
import {
  CreateEventInput,
  Event,
  UpdateEventInput,
  DeleteEventInput,
} from "../../graphql/API";
import { createEvent, deleteEvent, updateEvent } from "../../graphql/mutations";
import { listEvents } from "../../graphql/queries";

export default class EventAPI {
  async list(): Promise<Event[]> {
    const response: any = await API.graphql({
      query: listEvents,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.listEvents.items.filter(
      (item: Event) => !item._deleted
    );
  }

  async create(input: CreateEventInput) {
    return API.graphql({
      query: createEvent,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async update(input: UpdateEventInput) {
    return API.graphql({
      query: updateEvent,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }

  async delete(input: DeleteEventInput) {
    return API.graphql({
      query: deleteEvent,
      variables: {
        input,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
}
