import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import numeral from "numeral";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { Booking, BookingStatus } from "../../graphql/API";
import { bookingByShopId } from "../../graphql/queries";
import styles from "./index.module.scss";

export default function BookingList() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [bookings, setBookings] = useState<Booking[]>();
  const [nextToken, setNextToken] = useState<string>();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    setNextToken(undefined);

    fetch(currentShopId, undefined);
  }, [currentShopId]);

  async function fetch(shopId: string, nextToken: string | undefined) {
    const response: any = await API.graphql(
      graphqlOperation(bookingByShopId, {
        shopId,
        sortDirection: "DESC",
        filter: {
          bookingStatus: {
            ne: "CANCELED",
          },
        },
        nextToken,
      })
    );

    const { items, nextToken: newNextToken } = response.data.bookingByShopId;

    if (nextToken) {
      setBookings(bookings?.concat(items));
    } else {
      setBookings(items);
    }
    setNextToken(newNextToken);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>예약 기록</h4>
            <Table responsive>
              <colgroup>
                <col />
                <col />
                <col width="8%" />
                <col />
                <col />
                <col width="15%" />
                <col width="30%" />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>날짜</th>
                  <th>디자이너</th>
                  <th>고객</th>
                  <th>네이버 고유번호</th>
                  <th>상태</th>
                  <th>서비스 옵션</th>
                  <th>시술 메모</th>
                  <th>(예상) 금액</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {bookings?.map((booking) => {
                  return (
                    <tr key={booking.id}>
                      <td>{booking.date}</td>
                      <td>{booking.designerName}</td>
                      <td>{booking.customer?.name}</td>
                      <td>{booking.naverBookingNumber}</td>
                      <td>
                        <Badge
                          bg={
                            booking.bookingStatus === BookingStatus.COMPLETED
                              ? "success"
                              : "info"
                          }
                        >
                          {booking.bookingStatus}
                        </Badge>
                      </td>
                      <td>
                        {booking.options?.map((option) => {
                          return <div key={option.name}>{option.name}</div>;
                        })}
                        {booking.consultationId && (
                          <Badge bg="success">컨설테이션</Badge>
                        )}
                      </td>
                      <td>
                        <small>{booking.designerMemo}</small>
                      </td>
                      <td className="text-end">
                        {numeral(
                          booking.finalPrice || booking.initialPrice
                        ).format("0,0")}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="text-center">
              {nextToken && (
                <Button
                  onClick={() => {
                    fetch(currentShopId!, nextToken);
                  }}
                >
                  추가 조회
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
