import { API, graphqlOperation } from "aws-amplify";
import {
  Customer,
  CustomerByNameAndPhoneQueryVariables,
  CustomerByShopIdAndNameQueryVariables,
  CustomerByShopIdAndPhoneNumberQueryVariables,
} from "../../graphql/API";
import {
  customerByNameAndPhone,
  customerByShopIdAndName,
  customerByShopIdAndPhoneNumber,
  getCustomer,
} from "../../graphql/queries";
import { GraphQLResult } from "../types";

export default class CustomerAPI {
  async get(id: string): Promise<Customer> {
    const response: any = await API.graphql(
      graphqlOperation(getCustomer, {
        id,
      })
    );

    return response.data.getCustomer;
  }

  async listCustomersByShopId(
    shopId: string,
    name?: string,
    phoneNumber?: string
  ): Promise<Customer[]> {
    if (!name && !phoneNumber) {
      const variables: CustomerByShopIdAndNameQueryVariables = {
        shopId,
        limit: 1000,
      };
      const result = (await API.graphql(
        graphqlOperation(customerByShopIdAndName, variables)
      )) as any;

      return result.data.customerByShopIdAndName.items;
    }

    let customers: Customer[] = [];

    if (name) {
      const variables: CustomerByShopIdAndNameQueryVariables = {
        shopId,
        name: {
          beginsWith: name,
        },
      };
      const result = (await API.graphql(
        graphqlOperation(customerByShopIdAndName, variables)
      )) as any;

      customers = customers.concat(result.data.customerByShopIdAndName.items);
    }
    if (phoneNumber) {
      const variables: CustomerByShopIdAndPhoneNumberQueryVariables = {
        shopId,
        phoneNumber: {
          beginsWith: phoneNumber,
        },
      };
      const result = (await API.graphql(
        graphqlOperation(customerByShopIdAndPhoneNumber, variables)
      )) as any;

      customers = customers.concat(
        result.data.customerByShopIdAndPhoneNumber.items
      );
    }
    return customers;
  }

  async listCustomersByNameAndPhone(
    name: string,
    phoneNumber: string,
    shopId: string
  ): Promise<Customer[]> {
    const variables: CustomerByNameAndPhoneQueryVariables = {
      name,
      phoneNumber: {
        eq: phoneNumber,
      },
      filter: {
        shopId: {
          eq: shopId,
        },
      },
    };

    const result: GraphQLResult<{
      customerByNameAndPhone: {
        items: Customer[];
      };
    }> = (await API.graphql(
      graphqlOperation(customerByNameAndPhone, variables)
    )) as any;

    if (!result.data) {
      return [];
    }

    return result.data.customerByNameAndPhone.items;
  }
}
