import { API, Storage } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { useAppSelector } from "../../../app/hooks";
import {
  selectShowShopModal,
  setShowShopModal,
} from "../../../features/common";
import { fetchShops } from "../../../features/shop";
import { Shop, UpdateShopInput } from "../../../graphql/API";

const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      naverBusinessId
      hasLocker
      bookingQRS3Key
      creatorId
      usePreSelfDiagnosis
      useFeedbackRequest
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export default function ShopModal({ shop }: { shop: Shop }) {
  const show = useAppSelector(selectShowShopModal);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowShopModal(false));
  };

  const [input, setInput] = useState<UpdateShopInput>();
  const [uploading, setUploading] = useState(false);
  const [qrImageUrl, setQrImageUrl] = useState("");

  useEffect(() => {
    setInput({
      id: shop.id,
      name: shop.name,
      naverBusinessId: shop.naverBusinessId,
      hasLocker: shop.hasLocker,
      bookingQRS3Key: shop.bookingQRS3Key,
      usePreSelfDiagnosis: shop.usePreSelfDiagnosis,
      useFeedbackRequest: shop.useFeedbackRequest,
      internPhoneNumber: shop.internPhoneNumber,
      _version: shop._version,
    });

    if (shop.bookingQRS3Key) {
      setQrUrl(shop.bookingQRS3Key);
    } else {
      setQrImageUrl("");
    }
  }, [shop]);

  async function setQrUrl(key: string) {
    const qrImageUrl = await Storage.get(key);

    setQrImageUrl(qrImageUrl);
  }

  async function handleSaveChanges() {
    await API.graphql({
      query: updateShop,
      variables: { input },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    if (input?.bookingQRS3Key) {
      setQrUrl(input.bookingQRS3Key);
    }

    toast.success("업데이트했습니다.");

    dispatch(fetchShops() as any);
  }

  async function uploadFile(file: any) {
    const fileNameSlices = file.name.split(".");

    const fileNameExtension = fileNameSlices[fileNameSlices.length - 1];

    const fileName =
      dayjs().format("YYYYMMDD") +
      "/qrcode-" +
      uuid() +
      "." +
      fileNameExtension;

    const result = await Storage.put(fileName, file);

    const s3Key = result.key;

    setInput({
      ...input,
      bookingQRS3Key: s3Key,
    } as any);
  }

  async function onFileInputChange(event: any) {
    setUploading(true);

    try {
      let files = [];
      for (var i = 0; i < event?.target?.files.length; i++) {
        files.push(event.target.files.item(i));
      }
      await Promise.all(files.map((f, index) => uploadFile(f)));
    } catch (error: any) {
      toast.warn(error.message);
    } finally {
      setUploading(false);
    }
  }

  if (!shop || !input) {
    return <></>;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{shop.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  이름
                </Form.Label>
                <Form.Control
                  value={input.name ?? ""}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      name: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="8">
                  네이버 업체 고유 ID
                </Form.Label>
                <Form.Control
                  value={input.naverBusinessId ?? ""}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      naverBusinessId: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="8">
                  라커 보유 여부
                </Form.Label>
                <Form.Check
                  checked={input.hasLocker ?? false}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      hasLocker: event.target.checked,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <div>
                <Button
                  size="sm"
                  onClick={() =>
                    document.getElementById("add-image-file-input")?.click()
                  }
                  disabled={uploading}
                >
                  {uploading ? "업로드 중..." : "예약 QR 등록"}
                </Button>
                <input
                  id="add-image-file-input"
                  type="file"
                  accept="image/*"
                  multiple={false}
                  onChange={onFileInputChange}
                  style={{ display: "none" }}
                />
                {input.bookingQRS3Key}
                {qrImageUrl && (
                  <img src={qrImageUrl} style={{ width: "100px" }} />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="8">
                  사전 셀프 진단 사용 여부
                </Form.Label>
                <Form.Check
                  checked={input.usePreSelfDiagnosis ?? false}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      usePreSelfDiagnosis: event.target.checked,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="8">
                  시술 후 피드백 요청 사용 여부
                </Form.Label>
                <Form.Check
                  checked={input.useFeedbackRequest ?? false}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      useFeedbackRequest: event.target.checked,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                  인턴 연락처
                </Form.Label>
                <Form.Control
                  value={input.internPhoneNumber ?? ""}
                  onChange={(event) => {
                    setInput({
                      ...input,
                      internPhoneNumber: event.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            저장
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
