import { API, graphqlOperation } from "aws-amplify";
import {
  Caring,
  CreateCaringInput,
  UpdateCaringInput,
  CaringByYearMonthQueryVariables,
  ModelSortDirection,
} from "../../graphql/API";
import { createCaring, updateCaring } from "../../graphql/mutations";
import {
  getCaring,
  listCarings,
  caringByYearMonth,
} from "../../graphql/queries";

export default class CaringAPI {
  async get(bookingId: string): Promise<Caring> {
    const response: any = await API.graphql(
      graphqlOperation(getCaring, {
        bookingId,
      })
    );

    return response.data.getCaring;
  }

  async list(shopId: string): Promise<Caring[]> {
    const response: any = await API.graphql(
      graphqlOperation(listCarings, {
        filter: {
          shopId: {
            eq: shopId,
          },
        },
      })
    );

    return response.data.listCarings.items;
  }

  async listByYearMonth(yearMonth: string): Promise<Caring[]> {
    const variables: CaringByYearMonthQueryVariables = {
      yearMonth,
      sortDirection: ModelSortDirection.DESC,
    };

    const response: any = await API.graphql({
      query: caringByYearMonth.replace("owner", ""),
      variables,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    return response.data.caringByYearMonth.items;
  }

  async create(input: CreateCaringInput): Promise<Caring> {
    const response: any = await API.graphql(
      graphqlOperation(createCaring, {
        input,
      })
    );

    return response.data.createCaring;
  }

  async update(input: UpdateCaringInput) {
    if (!input._version) {
      throw new Error("Missing _version");
    }
    return API.graphql(
      graphqlOperation(updateCaring, {
        input,
      })
    );
  }
}
