import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import APIGateway from "../../api/api-gateway";
import {
  ConsultationSolutionPreset,
  CreateConsultationSolutionPresetInput,
} from "../../graphql/API";

export default function ConsultationSolutionPresetList() {
  const apiGateway = new APIGateway();

  const [category, setCategory] = useState("face");
  const [list, setList] = useState<ConsultationSolutionPreset[]>([]);

  const defaultInput = (
    category = "face"
  ): CreateConsultationSolutionPresetInput => {
    return {
      category,
      sentence: "",
      priority: 0,
    };
  };

  const categories = [
    {
      id: "face",
      name: "얼굴형",
    },
    {
      id: "hair",
      name: "모발",
    },
    {
      id: "needs",
      name: "니즈",
    },
    {
      id: "aftercareTips",
      name: "관리 방법",
    },
    {
      id: "stylingTips",
      name: "스타일링 방법",
    },
    {
      id: "precautions",
      name: "주의사항",
    },
  ];
  //   point: String
  //   recommendedTreatment: String

  const [input, setInput] = useState<CreateConsultationSolutionPresetInput>(
    defaultInput()
  );

  useEffect(() => {
    fetchConsultationSolutionPresets();
  }, []);

  async function fetchConsultationSolutionPresets() {
    const response = await apiGateway.consultationSolutionPreset.list();

    setList(response);
  }

  async function create() {
    const { sentence } = input;

    if (!sentence) {
      toast.warn("문장을 입력해 주세요.");
      return;
    }

    await apiGateway.consultationSolutionPreset.create(input);

    toast.success("생성되었습니다.");

    setInput(defaultInput(input.category));

    fetchConsultationSolutionPresets();
  }

  async function deleteItem(preset: ConsultationSolutionPreset) {
    if (!confirm("삭제하시겠습니까?\n" + preset.sentence)) {
      return;
    }
    await apiGateway.consultationSolutionPreset.delete({
      id: preset.id,
      _version: preset._version,
    });

    fetchConsultationSolutionPresets();
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>컨설테이션 솔루션 프리셋 관리</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {categories.map((item) => {
              return (
                <Button
                  key={item.id}
                  size="sm"
                  variant={
                    item.id === category ? "secondary" : "outline-secondary"
                  }
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    setCategory(item.id);
                    setInput({
                      ...input,
                      category: item.id,
                    });
                  }}
                >
                  {item.name}
                </Button>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <colgroup>
                <col width="10%" />
                <col width="80%" />
                <col width="10%" />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>문장</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list
                  .filter((item) => item.category === category)
                  .map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          {
                            categories.find((cat) => cat.id === item.category)
                              ?.name
                          }
                        </td>
                        <td>{item.sentence}</td>
                        <td>
                          <Button
                            onClick={() => {
                              deleteItem(item);
                            }}
                            size="sm"
                            variant="outline-warning"
                          >
                            삭제
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <Form.Select
              onChange={(event) => {
                setInput({
                  ...input,
                  category: event.target.value,
                });
              }}
              value={input.category ?? ""}
            >
              {categories.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col>
            <Form.Control
              placeholder="문장"
              value={input?.sentence}
              onChange={(event) => {
                setInput({
                  ...input,
                  sentence: event.target.value,
                });
              }}
            />
          </Col>
          <Col xs="auto">
            <Button onClick={create}>등록</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
