import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Badge,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DesignerAPI from "../../api/designer";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { Designer } from "../../graphql/API";

export default function DesignerList() {
  const dispatch = useDispatch();

  const designerAPI = new DesignerAPI();

  const currentShopId = useAppSelector(selectCurrentShopId);

  const [designers, setDesigners] = useState<Designer[]>([]);
  const [designerInputs, setDesignerInputs] = useState<Designer[]>([]);

  useEffect(() => {
    if (currentShopId) fetchDesigners(currentShopId);
  }, [currentShopId]);

  async function fetchDesigners(shopId: string) {
    const list = await designerAPI.listDesigners(shopId);

    setDesigners(list);
    setDesignerInputs(list);
  }

  async function onUpdateClick(id: string, _version: number) {
    const designer = designerInputs.find((designer) => designer.id === id);

    if (!designer) return;

    const { phoneNumber, imageUrl, instagramUrl, youtubeUrl } = designer;

    await designerAPI.updateDesigner({
      id,
      _version,
      phoneNumber,
      imageUrl,
      instagramUrl,
      youtubeUrl,
    });

    toast.success("업데이트 되었습니다.");

    designerAPI.listDesigners(currentShopId!);
  }

  async function addCustomDesigner() {
    if (!currentShopId) {
      return;
    }

    if (
      !confirm("개발자를 위한 기능입니다. 꼭 필요할 때 사용하세요. 진행할까요?")
    ) {
      return;
    }

    await designerAPI.createDesigner({
      shopId: currentShopId,
      name: "수동 생성",
    });

    fetchDesigners(currentShopId);
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>디자이너 관리</h4>
            <Table responsive>
              <colgroup>
                <col width="8%" />
                <col width="8%" />
                <col width="8%" />
                <col width="12%" />
                <col width="14%" />
                <col width="5%" />
                <col width="14%" />
                <col width="14%" />
                <col width="8%" />
                <col />
                <col />
              </colgroup>
              <thead>
                <th>이름</th>
                <th>네이버 업체 고유 ID</th>
                <th>네이버 상품 고유 ID</th>
                <th>전화번호</th>
                <th>사진</th>
                <th></th>
                <th>인스타그램</th>
                <th>유튜브</th>
                <th></th>
                <th>생성일</th>
                <th>변경일</th>
              </thead>
              <tbody>
                {designers.map((designer) => {
                  const designerInput = designerInputs.find(
                    (item) => item.id === designer.id
                  );

                  return (
                    <tr key={designer.id}>
                      <td>
                        {designer.name}
                        {designer.inactive && (
                          <>
                            <br />
                            <Badge bg="secondary">비활성</Badge>
                          </>
                        )}
                      </td>
                      <td>{designer.businessId}</td>
                      <td>{designer.bizItemId}</td>
                      <td>
                        <Form.Control
                          type="text"
                          value={designerInput?.phoneNumber ?? ""}
                          onChange={(event) => {
                            const value = event.target.value;
                            setDesignerInputs((prev) => {
                              return prev.map((item) => {
                                if (item.id === designer.id) {
                                  return {
                                    ...item,
                                    phoneNumber: value,
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={designerInput?.imageUrl ?? ""}
                          onChange={(event) => {
                            const value = event.target.value;
                            setDesignerInputs((prev) => {
                              return prev.map((item) => {
                                if (item.id === designer.id) {
                                  return {
                                    ...item,
                                    imageUrl: value,
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                      </td>
                      <td>
                        {designer.imageUrl && (
                          <img
                            src={designer.imageUrl}
                            style={{
                              width: 38,
                              height: 38,
                              objectFit: "cover",
                              borderRadius: 45,
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={designerInput?.instagramUrl ?? ""}
                          onChange={(event) => {
                            const value = event.target.value;
                            setDesignerInputs((prev) => {
                              return prev.map((item) => {
                                if (item.id === designer.id) {
                                  return {
                                    ...item,
                                    instagramUrl: value,
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          value={designerInput?.youtubeUrl ?? ""}
                          onChange={(event) => {
                            const value = event.target.value;
                            setDesignerInputs((prev) => {
                              return prev.map((item) => {
                                if (item.id === designer.id) {
                                  return {
                                    ...item,
                                    youtubeUrl: value,
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            onUpdateClick(designer.id, designer._version);
                          }}
                        >
                          업데이트
                        </Button>
                      </td>
                      <td>
                        {dayjs(designer.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        {dayjs(designer.updatedAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div>
              <Button size="sm" onClick={addCustomDesigner}>
                나나로그 솔루션 계정 없는 디자이너 수동 추가
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
