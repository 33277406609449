import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Row, Col, Table, Container, Badge, Button } from "react-bootstrap";
import CustomerAPI from "../../api/customer";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentShopId } from "../../features/common";
import { Customer } from "../../graphql/API";
import styles from "./index.module.scss";

export default function CustomerList() {
  const currentShopId = useAppSelector(selectCurrentShopId);
  const [customers, setCustomers] = useState<Customer[]>();

  const customerAPI = new CustomerAPI();

  useEffect(() => {
    if (!currentShopId) {
      return;
    }

    initialize(currentShopId);
  }, [currentShopId]);

  async function initialize(shopId: string) {
    const data = await customerAPI.listCustomersByShopId(shopId);

    setCustomers(data);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h4>고객 목록</h4>
            <Table responsive>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>직업</th>
                  <th>음료</th>
                  <th>메모</th>
                  <th>등록일시</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer) => {
                  return (
                    <tr key={customer.id}>
                      <td>{customer.name}</td>
                      <td>{customer.occupation}</td>
                      <td>{customer.drink}</td>
                      <td>{customer.memo}</td>
                      <td>
                        {dayjs(customer.createdAt).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
