import { API } from "aws-amplify";
import {
  CreateFeedbackInput,
  Feedback,
  FeedbackByShopIdQueryVariables,
  ModelSortDirection,
  UpdateFeedbackInput,
} from "../../graphql/API";
import { createFeedback, updateFeedback } from "../../graphql/mutations";
import { feedbackByShopId } from "../../graphql/queries";

export default class FeedbackAPI {
  async list(shopId: string): Promise<Feedback[]> {
    const variables: FeedbackByShopIdQueryVariables = {
      shopId,
      sortDirection: ModelSortDirection.DESC,
    };

    const response: any = await API.graphql({
      query: feedbackByShopId,
      variables,
    });
    return response.data.feedbackByShopId.items;
  }

  async create(input: CreateFeedbackInput) {
    return API.graphql({
      query: createFeedback,
      variables: {
        input,
      },
    });
  }

  async update(input: UpdateFeedbackInput) {
    return API.graphql({
      query: updateFeedback,
      variables: {
        input,
      },
    });
  }
}
